import { createInstance } from 'src/api';
import {
    type ChatConversationDeleteResponse,
    type ParsedChatConversationHistoryResponse,
    type ChatConversationResponse,
    type AlphabotExamplesRawResponse,
    type AlphabotExamplesResponse,
} from 'src/features/chatbot/types';
import { appConfig } from 'src/lib/config';

const cbApi = createInstance({ baseURL: appConfig.chatbotApi });

// type NewsSummaryResponse = Static<(typeof schema)['/v1/summarize_news']['POST']['data']>;
type NewsSummaryResponse = {
    result: {
        abstract: string;
        summary: string;
    };
};

export const getNewsSummaryForTicker = async (ticker: string, from: string, to: string) => {
    const {
        data: { result },
    } = await cbApi.post<NewsSummaryResponse>(`/v1/summarize_news`, {
        request_type: 'summarize_news',
        symbol_or_search_string: ticker,
        query_type: 'symbol',
        from_timestamp: from,
        to_timestamp: to,
    });

    return result;
};

export const getChatConversation = async (user_id: string, conversationId: string) => {
    const { data } = await cbApi.get<ChatConversationResponse>(`/chat/${user_id}/${conversationId}`);

    return data;
};

export const getChatConversationHistory = async (user_id: string) => {
    const { data } = await cbApi.get<ParsedChatConversationHistoryResponse>(`/chat/${user_id}`);

    return data;
};

export const getChatExamples = async (): Promise<AlphabotExamplesResponse[]> => {
    const { data } = await cbApi.get<AlphabotExamplesRawResponse>(`/chat/examples`);

    if (data.example_queries.length === 0) {
        return [];
    }

    return data.example_queries;
};

export const deleteChatConversation = async (user_id: string, conversationId: string) => {
    const { data } = await cbApi.get<ChatConversationDeleteResponse>(`/chat/${user_id}/${conversationId}/delete`);

    return data;
};
