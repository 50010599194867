import type { OrderEntryWidgetModel as OrderEntryWidgetModelContract } from 'src/contracts/workspace';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { OrderEntryWidgetModel } from 'src/models/OrderEntryWidgetModel';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { userSelectedNewOrderEntrySymbol } from 'src/store/actions/widgets/orderEntry';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class OrderEntryWidgetModelImpl extends ReactiveInjectable implements OrderEntryWidgetModel {
    #skipTradeConfirmation = false;

    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    private get widget(): OrderEntryWidgetModelContract {
        return this.widgetData.widget as OrderEntryWidgetModelContract;
    }

    set accountId(accountId: string | null) {
        this.update({ accountId }); // sync up the account id to the channel data in redux
    }

    get accountId() {
        return this.widget.accountId;
    }

    get symbol() {
        return this.widget.symbol ?? '';
    }

    set symbol(symbol: string) {
        this.store.dispatch(userSelectedNewOrderEntrySymbol(this.widgetData.tabId, symbol));
    }

    @reacts set skipTradeConfirmation(skipTradeConfirmation: boolean) {
        this.#skipTradeConfirmation = skipTradeConfirmation;
    }

    get skipTradeConfirmation() {
        return this.#skipTradeConfirmation;
    }

    convertToSimpleVariant() {
        this.store.dispatch(
            updateWidget({
                tabId: this.widgetData.tabId,
                widgetUpdates: {
                    type: 'simple-order-entry',
                },
                tabUpdates: {
                    name: 'Trade',
                },
            }),
        );
    }

    /**
     * support syncing accountId and symbol to the channel data in redux
     *
     * @param updates
     */
    private update(
        updates: Partial<{
            symbol: string;
            accountId: string | null;
        }>,
    ) {
        this.store.dispatch(
            updateWidget({
                tabId: this.widgetData.tabId,
                widgetUpdates: {
                    ...updates,
                },
            }),
        );
    }
}
