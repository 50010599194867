import { getNewsForTicker } from './http/news';
import { ONE_MINUTE } from 'src/lib/util/timeConstants';

// Constants
const NEWS = 'news';

export const getNewsQuery = (tickerSymbol: string, disabled = false) => ({
    queryKey: [NEWS, tickerSymbol],
    queryFn: () => getNewsForTicker(tickerSymbol),
    enabled: !disabled,
    refetchInterval: ONE_MINUTE * 5, // refetch every 5 minutes
});
