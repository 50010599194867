import { QueryObserverModel } from '.';
import { type ReadonlySignal, type Signal, signal } from '@preact/signals-react';
import { QueryObserverOptions, QueryObserverResult, QueryObserver } from '@tanstack/query-core';
import { inject, injectable } from 'src/features/ioc';
import { ReactBindings } from 'src/types/bindings';

@injectable()
export class QueryObserverModelImpl<TQueryFnData, TError, TQueryData>
    implements QueryObserverModel<TQueryFnData, TError, TQueryData>
{
    constructor(@inject('QueryClient') private queryClient: ReactBindings['QueryClient']) {}

    /**
     * Resources associated with this instance
     */
    #disposableStack = new DisposableStack();

    /**
     * @deprecated Read from queryModel.signal instead
     */
    getCurrentResult() {
        return this.#query.getCurrentResult();
    }

    init(options: QueryObserverOptions<TQueryFnData, TError, TQueryData>) {
        this.#query = new QueryObserver(this.queryClient, options);
        this.#disposableStack.defer(() => this.#query.destroy());

        this.#signal = signal(this.#query.getCurrentResult());

        this.#disposableStack.defer(this.#query.subscribe((result) => (this.#signal.value = result)));
    }

    #query!: QueryObserver<TQueryFnData, TError, TQueryData>;

    setQueryOptions(options: QueryObserverOptions<TQueryFnData, TError, TQueryData>) {
        this.#query.setOptions(options);
    }

    #signal!: Signal<QueryObserverResult<TQueryData, TError>>;

    get signal(): ReadonlySignal<QueryObserverResult<TQueryData, TError>> {
        return this.#signal;
    }

    /**
     * @deprecated Read from queryModel.signal instead
     */
    subscribe(callback: (result: QueryObserverResult<TQueryData, TError>) => void) {
        const unsubscribe = this.#query.subscribe(callback);

        // This is slightly wonky, but we ensure the subscriber is called once initially with the current value
        callback(this.#query.getCurrentResult());

        const disposable = {
            [Symbol.dispose]: () => unsubscribe(),
        };

        this.#disposableStack.use(disposable);

        return disposable;
    }

    [Symbol.dispose]() {
        this.#disposableStack?.dispose();
    }
}
