import {
    deleteChatConversation,
    getChatConversation,
    getChatConversationHistory,
    getChatExamples,
    getNewsSummaryForTicker,
} from './http/chatBot';
import type { QueryClient } from '@tanstack/react-query';
import type { LookBackPeriod } from 'src/features/newsFeed/components/NewsSummary';
import { ONE_MINUTE } from 'src/lib/util/timeConstants';

// Constants
const CHAT_BOT = 'chat-bot';
const NEWS_SUMMARY = 'news-summary';
const CHAT_CONVERSATION = 'chat-conversation';
const EXAMPLES = 'examples';

export const getNewsSummaryQuery = (tickerSymbol: string, ...lookBackPeriod: LookBackPeriod) => {
    const [from, to] = lookBackPeriod;

    return {
        queryKey: [CHAT_BOT, NEWS_SUMMARY, tickerSymbol],
        queryFn: () => getNewsSummaryForTicker(tickerSymbol, from, to),
        enabled: !!tickerSymbol,
        refetchInterval: ONE_MINUTE * 5, // refetch every 5 minutes
    };
};

export const getChatConversationQuery = (userId: string, conversationId: string | undefined) => {
    return {
        queryKey: [CHAT_BOT, CHAT_CONVERSATION, userId, conversationId],
        queryFn: () => getChatConversation(userId, conversationId ?? ''),
        enabled: !!conversationId,
    };
};

export const makeChatHistoryKey = (userId: string) => {
    return [CHAT_BOT, CHAT_CONVERSATION, userId];
};

export const getChatConversationHistoryQuery = (userId: string) => {
    return {
        queryKey: makeChatHistoryKey(userId),
        queryFn: () => getChatConversationHistory(userId),
        enabled: !!userId,
    };
};

export const getChatExamplesQuery = () => {
    return {
        queryKey: [CHAT_BOT, EXAMPLES],
        queryFn: () => getChatExamples(),
    };
};

export const deleteChatConversationMutation = (userId: string, conversationId: string, queryClient: QueryClient) => {
    // optimistically update cache and remove the conversation from the list
    queryClient.setQueryData(
        makeChatHistoryKey(userId),
        (oldData: Awaited<ReturnType<typeof getChatConversationHistory>> | undefined) => {
            if (!oldData) return oldData;

            return {
                ...oldData,
                conversation_list: oldData.conversation_list.filter(
                    (conversation) => conversation.conversation_id !== conversationId,
                ),
            };
        },
    );

    return {
        mutationKey: [CHAT_BOT, CHAT_CONVERSATION, userId, conversationId],
        mutationFn: () => deleteChatConversation(userId, conversationId),
    };
};
