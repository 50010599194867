import { ReadonlySignal, signal } from '@preact/signals-react';
import { MutationObserver } from '@tanstack/query-core';
import { container } from 'src/StaticContainer';
import { ChatClientModelImpl } from 'src/features/chatbot/models/ChatClientModel/impl';
import type { ChatClientModel } from 'src/features/chatbot/models/ChatClientModel/index';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { ChatWidgetModel } from 'src/models/ChatWidgetModel';
import { deleteChatConversationMutation } from 'src/queries/chatBot';
import { RootState } from 'src/store';
import { getAccessTokens, getUser } from 'src/store/selectors';
import { UserDetails } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

const log = container.get('Logger').getSubLogger({ name: 'ChatWidgetModel' });

@injectable()
export class ChatWidgetModelImpl extends ReactiveInjectable implements ChatWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('TableModel') private totalTableModel: ReactBindings['TableModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#chatClient = this.disposableStack.use(new ChatClientModelImpl(this.queryClient));
    }

    #chatClient: ChatClientModel;
    get chatClient() {
        return this.#chatClient;
    }

    async init(tabId: string) {
        this.widgetData.init(tabId);

        this.#connectChatClient({
            user: getUser(this.store.getState()),
            authTokens: getAccessTokens(this.store.getState()),
        });
    }

    #connectChatClient({
        user,
        authTokens,
    }: {
        user?: UserDetails;
        authTokens: Pick<RootState['auth'], 'accessToken' | 'masqAccessToken'>;
    }) {
        const token = authTokens.masqAccessToken?.token ?? authTokens.accessToken?.token;

        this.#chatClient.connect(user?.id, token);
    }

    deleteConversation = (conversationId: string): void => {
        const user = getUser(this.store.getState());

        if (!user?.id) {
            throw new Error('User not found');
        }

        // if we are deleting the current conversation, we need to start a new one
        if (this.#chatClient.conversationId.value === conversationId) {
            this.#chatClient.startNewConversation();
        }

        const mutation = new MutationObserver(
            this.queryClient,
            deleteChatConversationMutation(user?.id, conversationId, this.queryClient),
        );

        mutation.mutate(undefined, {
            onError: (error) => {
                log.error('Failed to delete conversation', { error });
                // TODO: add rollback of optimistic delete.
            },
        });
    };
    #showSidebar = signal(true);
    get showSidebar() {
        return this.#showSidebar as ReadonlySignal<boolean>;
    }

    #showDebug = signal(false);
    get showDebug() {
        return this.#showDebug as ReadonlySignal<boolean>;
    }

    toggleDebug = () => {
        this.#showDebug.value = !this.#showDebug.value;
    };

    toggleSidebar = () => {
        this.#showSidebar.value = !this.#showSidebar.value;
    };
}
