import { eventsReducer } from './events';
import { executionsReducer } from './executions';
import { lensReducer } from './lens';
import { locatesReducer } from './locates';
import { messagesReducer } from './messages';
import { newsReducer } from './news';
import { orderBlotterReducer } from './orderBlotter';
import { orderEntryReducer } from './orderEntry';
import { ordersReducer } from './orders';
import { positionsReducer } from './positions';
import { resultsReducer } from './results';
import { scannerReducer } from './scanner';
import { screenerReducer } from './screener';
import { searchAlphaReducer } from './searchAlphaReducer';
import { timeSeriesReducer } from './timeSeries';
import { watchlistReducer } from './watchlist';
import type { AppAction } from 'src/store/actions';
import type { UpdateTabNameAction } from 'src/store/actions/tab';
import type { OnTabChannelChangeEvent, UpdateWidgetAction } from 'src/store/actions/widgetAndChannel';
import { combineReducersFlat } from 'src/store/lib/combineReducersFlat';
import type { WidgetTabViewModel, WidgetViewModel } from 'src/store/types';

type TargetedWidgetUpdateAction = UpdateTabNameAction | UpdateWidgetAction | OnTabChannelChangeEvent;

const targetedWidgetReducer = (state: WidgetTabViewModel, action: TargetedWidgetUpdateAction): WidgetTabViewModel => {
    switch (action.type) {
        case 'updateTabName':
            return {
                ...state,
                name: action.tabName,
            };

        case 'updateWidget':
            return {
                ...state,
                ...(action.tabUpdates ? action.tabUpdates : {}),
                widget: { ...state.widget, ...action.widgetUpdates } as WidgetViewModel,
            };

        case 'onTabChannelChange':
            return {
                ...state,
                channelId: action.channelId ? action.channelId : null,
                widget: { ...state.widget, channelId: action.channelId },
            };

        default:
            return state;
    }
};

export const tabIdReducer = (state: WidgetTabViewModel, action: AppAction): WidgetTabViewModel => {
    if (!('tabId' in action)) return state;
    if (action.tabId !== state.id) return state;

    return combineReducersFlat<WidgetTabViewModel>([
        targetedWidgetReducer,
        eventsReducer,
        executionsReducer,
        lensReducer,
        locatesReducer,
        messagesReducer,
        newsReducer,
        orderBlotterReducer,
        orderEntryReducer,
        ordersReducer,
        positionsReducer,
        resultsReducer,
        scannerReducer,
        screenerReducer,
        searchAlphaReducer,
        timeSeriesReducer,
        watchlistReducer,
    ])(state, action);
};
