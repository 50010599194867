import {
    parser,
    replaceNodes,
    render,
    iterateAst,
    AstNodeType,
    type KnownAstNode,
    analyzer,
    recurseParen,
} from '@thinkalpha/language-services';
import type {
    BespokeIndicatorImport,
    IndicatorFormula,
    IndicatorImportRef,
} from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { isEqual } from 'es-toolkit';
import { container } from 'src/StaticContainer';
import { type SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { type IndicatorImportModel } from 'src/dtos/IndicatorImport';
import { v4 } from 'uuid';

const log = container.get('Logger').getSubLogger({ name: 'feat:validationMode' });

function uuidToName(id: string): string {
    return '_' + id.toString().replaceAll('-', '');
}

const generateName = () => uuidToName(v4());

const getReferencedImports = (node: KnownAstNode, imports: IndicatorImportRef[]): IndicatorImportRef[] => {
    return iterateAst(node)
        .filter((node) => node.type === AstNodeType.call)
        .reduce((acc, node) => {
            const importViewModel = imports.find((importViewModel) => importViewModel.alias === node.source);
            if (importViewModel) {
                acc.push(importViewModel);
            }
            return acc;
        }, [] as IndicatorImportRef[]);
};

const createSyntheticCallNode = (alias: string): KnownAstNode => ({
    type: AstNodeType.call,
    source: alias,
    arguments: [],
    errors: [],
    warnings: [],
    path: '',
    ranges: {
        eachArgument: [],
        node: { start: 0, end: 0 },
        source: { start: 0, end: 0 },
        openParen: { start: 0, end: 0 },
        closeParen: { start: 0, end: 0 },
    },
    valid: true,
});

export async function strategyGenerator(
    plan: SearchPlanDTO,
    imports: IndicatorImportModel[],
): Promise<[BespokeIndicatorImport[], IndicatorFormula] | null> {
    // Render the strategy to a formula
    const results = await plan.renderToFormula();
    if (!results) return null;
    const { formula } = results;

    let parserResult = parser(formula, {});

    if (!parserResult.root) {
        return null;
    }

    const root = analyzer(parserResult.root, {
        functionDefs: imports.map((i) => i.toDictionaryFunctionDef()),
    });

    parserResult = {
        ...parserResult,
        root,
    };

    if (!parserResult.root) {
        return null;
    }

    const replacements: WeakMap<KnownAstNode, KnownAstNode> = new WeakMap();

    const bespokeIndicators: BespokeIndicatorImport[] = [];

    for (const node of iterateAst(parserResult.root)) {
        if (node?.dataType !== 'boolean') continue;

        switch (node.type) {
            case 'binaryOperation': {
                if (node.dataType !== 'boolean') break;

                let lhs: KnownAstNode | BespokeIndicatorImport = node.lhs;

                if (
                    node.lhs.dataType !== 'boolean' &&
                    recurseParen(node.lhs).type !== AstNodeType.const &&
                    node.lhs.dataType
                ) {
                    const indicatorFormula: IndicatorFormula = {
                        formula: render(node.lhs),
                        // Figure out which imports node.lhs references
                        imports: getReferencedImports(node.lhs, plan.imports),
                    };

                    const potentialIndicator = bespokeIndicators.find((i) => isEqual(i.formula, indicatorFormula));
                    if (potentialIndicator) {
                        lhs = potentialIndicator;
                    } else {
                        // Create bespoke indicator for LHS and RHS and replace node with new LHS and RHS
                        lhs = {
                            id: v4(),
                            formula: indicatorFormula,
                            dataType: node.lhs.dataType!,
                            alias: generateName(),
                            unit: node.lhs.unit ?? null,
                            version: 1,
                            rowKey: 'ticker',
                        } satisfies BespokeIndicatorImport;

                        bespokeIndicators.push(lhs);
                    }
                } else if (!node.lhs.dataType) {
                    log.warn({ message: 'Node is missing dataType', lhs: node.lhs });
                }

                let rhs: KnownAstNode | BespokeIndicatorImport | null = node.rhs;
                if (
                    node.rhs?.dataType &&
                    node.rhs.dataType !== 'boolean' &&
                    recurseParen(node.rhs).type !== AstNodeType.const
                ) {
                    const indicatorFormula: IndicatorFormula = {
                        formula: render(node.rhs),
                        // Figure out which imports node.rhs references
                        imports: getReferencedImports(node.rhs, plan.imports),
                    };
                    const potentialIndicator = bespokeIndicators.find((i) => isEqual(i.formula, indicatorFormula));
                    if (potentialIndicator) {
                        lhs = potentialIndicator;
                    } else {
                        rhs = {
                            id: v4(),
                            formula: indicatorFormula,
                            dataType: node.rhs.dataType,
                            alias: generateName(),
                            unit: node.rhs.unit ?? null,
                            version: 1,
                            rowKey: 'ticker',
                        } satisfies BespokeIndicatorImport;

                        bespokeIndicators.push(rhs);
                    }
                } else if (!node.rhs?.dataType) {
                    log.warn({ message: 'Node is missing dataType', rhs: node.rhs });
                }

                const newNode: KnownAstNode = {
                    ...node,
                    lhs: 'type' in lhs ? lhs : createSyntheticCallNode(lhs.alias),
                    rhs: rhs === null ? rhs : 'type' in rhs ? rhs : createSyntheticCallNode(rhs.alias),
                };

                replacements.set(node, newNode);
            }
        }
    }

    const replaced = replaceNodes(parserResult.root, (node) => {
        if (!node) return node;

        if (replacements.has(node)) {
            return replacements.get(node)!;
        }
        return node;
    });

    return [
        bespokeIndicators,
        {
            formula: render(replaced),
            imports: [...plan.imports.map((i) => i.toContractType()), ...bespokeIndicators],
        },
    ];
}
