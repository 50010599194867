import { processQuery } from './paging';
import type { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { isAxiosError } from 'axios';
import { api } from 'src/api';

export async function getUniverses(): Promise<Universe[]> {
    const x = await api.get<Universe[]>(`/universes${processQuery({ limit: 250 })}`);
    return x.data;
}

export async function getUniverseById(id: string): Promise<Universe | undefined> {
    try {
        const response = await api.get<Universe>(`/universes/${id}`);

        return {
            ...response.data,
        };
    } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
            return undefined;
        }

        throw error;
    }
}

export async function getDefaultUniverse(): Promise<Universe> {
    const x = await api.get<Universe>(`/universes/default`);
    return x.data;
}
