import type { Tag } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { api } from 'src/api';
import type { SlugMap } from 'src/lib/dictionary/dictionary';

export async function getDictionaryTags(): Promise<Tag[]> {
    const { data } = await api.get<Tag[]>('/dictionary/tags/indexed');
    return data;
}

export function getWholeSlugMap(): Promise<SlugMap> {
    return api.get(`/dictionary/indicators/slugmap`).then((res) => res.data);
}
