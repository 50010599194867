import { LocalDate } from '@js-joda/core';
import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { QueryObserverOptions } from '@tanstack/query-core';
import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { TableCreationResultWithColumns } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { from, tap, timer } from 'rxjs';
import { Event, EventType } from 'src/components/ui/EventList';
import { EventWithSymbol } from 'src/components/ui/EventList/InfiniteEventsList';
import { IdeaDTO } from 'src/dtos/Idea';
import { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { QueryFn, Page } from 'src/features/BidirectionalInfiniteDataloader';
import { getEvents } from 'src/features/events/api';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { ScannerPlanDTO } from 'src/features/scanner/dtos/ScannerPlanDTO';
import { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { WatchlistPlanDTO } from 'src/features/watchlist/dtos/WatchlistPlanDTO';
import { createTable } from 'src/lib/table';
import { type Logger } from 'src/services/Logger';
import { createAlphaLensFromSymbol, createChartFromSymbol } from 'src/store/actions/container';
import {
    userSetEventsEventTypes,
    userSetEventsGoToDate,
    userSetEventsSymbolFilter,
    userSetEventsUniverse,
} from 'src/store/actions/widgets/events';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { EventsWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

// Helper to compare arrays for equality
function arraysEqual<T>(a: T[], b: T[]): boolean {
    if (a === b) return true;
    if (a.length !== b.length) return false;
    return a.every((val, idx) => val === b[idx]);
}

type EventsWidgetModelState =
    | { type: 'initializing' }
    | {
          type: 'initialized';
          changeCloseSlug: Signal<ConcreteIndicator>;
          symbolSlug: Signal<ConcreteIndicator>;
          symbolFilter: Signal<string | null>;
          universeId: Signal<string | null>;
      };

@injectable()
export class ChronologicalEventsWidgetModelImpl
    extends ReactiveInjectable
    implements ChronologicalEventsWidgetModelImpl
{
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('Logger') logger: Logger,
        @inject('DefaultUniverseServiceProvider')
        private readonly defaultUniverseServiceProvider: ReactBindings['DefaultUniverseServiceProvider'],
        @inject('SlugMapServiceProvider')
        private readonly slugMapServiceProvider: ReactBindings['SlugMapServiceProvider'],
        @inject('TableModel') private readonly table: ReactBindings['TableModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#state = { type: 'initializing' };

        this.#logger = logger.getSubLogger({ name: 'EventsWidgetModel' });
    }

    get fastIdeas(): ReadonlySignal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]> {
        return this.#fastIdeas as ReadonlySignal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]>;
    }

    #fastIdeas = signal<(IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]>([]);

    get gotoDate() {
        return this.widget.gotoDate;
    }

    async init(tabId: string) {
        this.widgetData.init(tabId);
        this.disposableStack.use(
            from(this.widgetData).subscribe(() => {
                const widget = this.widgetData.widget as EventsWidgetViewModel;
                if (widget.universeId !== this.#userSelectedUniverseId.value) {
                    if (TRACE) {
                        this.#logger.trace({ message: 'User selected new universe', universeId: widget.universeId });
                    }
                    this.#userSelectedUniverseId.value = widget.universeId;
                }

                // Keep eventTypes signal in sync with widget data
                if (widget.eventTypes && !arraysEqual(widget.eventTypes, this.#eventTypes.value)) {
                    if (TRACE) {
                        this.#logger.trace({
                            message: 'Event types changed from redux',
                            eventTypes: widget.eventTypes,
                        });
                    }
                    this.#eventTypes.value = widget.eventTypes;
                }
            }),
        );

        // Initialize the event types signal with data from the widget
        this.#eventTypes.value = this.widget.eventTypes || [];

        const defaultUniverseService = await this.defaultUniverseServiceProvider();
        const slugMapService = await this.slugMapServiceProvider();

        const symbolSlug = computed(() => slugMapService.slugMap.value['symbol']);
        const changeCloseSlug = computed(() => slugMapService.slugMap.value['symbol::changeClose']);

        const symbolFilter = signal<string | null>(null);

        const universeId = computed(
            () => this.#userSelectedUniverseId.value ?? defaultUniverseService.universe.value.id,
        );
        const queryOptions: Signal<QueryObserverOptions<TableCreationResultWithColumns>> = computed(() => ({
            queryKey: ['events-widget', 'table', universeId.value],
            queryFn: () => {
                return createTable({
                    leafStage: {
                        type: 'output',
                        columns: [
                            { id: 'symbol', type: 'indicator', indicator: symbolSlug.value },
                            { id: 'changeClose', type: 'indicator', indicator: changeCloseSlug.value },
                        ],
                        inputStage: {
                            type: 'row-source',
                            rowKey: 'ticker',
                            // @ts-expect-error Refactoring this to use universes correctly as part of chronological queries
                            universe: symbolFilter.value
                                ? {
                                      aspects: [],
                                      inclusionList: [symbolFilter.value],
                                      exclusionList: [],
                                      isTemplate: false,
                                  }
                                : (universeId.value ?? undefined),
                        },
                    },
                });
            },
        }));

        this.table.init({
            requestId: v4(),
            initBounds: { firstRow: 0, windowSize: 100000 },
            queryOptions,
        });

        // Initialize the chronological query manager with the events API
        /*
        this.#chronologicalQuery = new ChronologicalQueryManager<EventRow, LocalDate>({
            queryFn: async (symbols, prevRow, abortSignal) => {
                if (!symbols || symbols.length === 0) return [];

                try {
                    // Use the existing getEvents API to fetch event data
                    const events = await getEvents({
                        symbols: symbols,
                        eventTypes: this.#eventTypes.value,
                        end: prevRow ? prevRow.timestamp : (this.widget.gotoDate ?? LocalDate.now().plusDays(1)),
                        referenceId: prevRow?.id,
                        count: 100,
                        abortSignal,
                    });

                    // Transform the events into the EventRow format required by ChronologicalQueryManager
                    return events.map((event) => ({
                        ...event,
                        symbols: [event.symbol], // ChronologicalQueryRow requires symbols array
                        id: event.eventId, // Map id from eventId
                        timestamp: event.exDate, // Use exDate as the timestamp for chronological ordering
                    }));
                } catch (error) {
                    this.#logger.error('Error fetching events', { error });
                    return [];
                }
            },
            maxPageSize: 100,
            maxQueryLatency: Duration.ofMillis(250),
            livePollingFrequency: Duration.ofSeconds(30),
        });
        */

        // TODO: Use table updates to update the chronological query directly
        /*
        this.disposableStack.use(
            this.table.updates$.subscribe((updates) => {
                for (const update of updates) {
                    if (TRACE) {
                        this.#logger.trace('Table update', { update });
                    }
                }
            }),
        );
        */

        // Connect table updates to chronological query using a single subscription pipeline
        let abortLastRequest = new AbortController();
        this.disposableStack.use(
            timer(0, 2500)
                .pipe(
                    tap(async () => {
                        abortLastRequest.abort();

                        const thisRequestAbortController = new AbortController();
                        abortLastRequest = thisRequestAbortController;

                        if (TRACE) this.#logger.trace('Polling');

                        const snapshot = await this.table.snapshot({ firstRow: 0, windowSize: 100000 });

                        if (thisRequestAbortController.signal.aborted) return;

                        if (!snapshot) return;

                        const symbols = snapshot?.data.map((row) => row['symbol'] as string) ?? [];
                        if (TRACE) this.#logger.trace('Table snapshot symbols', { symbols });

                        // Update the internal table symbols state
                        // The effect will automatically update tracked symbols when this changes
                        this.#tableSymbols.value = symbols;
                    }),
                )
                .subscribe(),
        );

        // Initialize the query function for the InfiniteTimeView
        this.#initQueryFunction();

        this.disposableStack.use(
            from(this.widgetData).subscribe(() => {
                this.#initQueryFunction();
            }),
        );

        this.#state = {
            type: 'initialized',
            changeCloseSlug,
            symbolSlug,
            symbolFilter,
            universeId,
        };
    }

    #logger: Logger;

    onGoToDate(date: LocalDate | null) {
        this.store.dispatch(userSetEventsGoToDate(this.widgetData.tabId, date));
    }

    onSelectEventTypes(eventTypes: EventType[]): void {
        this.store.dispatch(userSetEventsEventTypes(this.widgetData.tabId, eventTypes));
    }

    onChangeSymbolFilter(symbolFilter: string): void {
        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, symbolFilter || null));
    }

    onSelectSlowIdeas(ideas: (IdeaDTO<ScreenerPlanDTO> | IdeaDTO<WatchlistPlanDTO> | { universeId: string })[]): void {
        // Sync universe ID to redux
        const reduxUniverse = this.#userSelectedUniverseId.value;
        const selectedUniverse = ideas.find((idea) => 'universeId' in idea)?.universeId ?? null;

        if (reduxUniverse !== selectedUniverse) {
            this.store.dispatch(userSetEventsUniverse(this.widgetData.tabId, selectedUniverse));
        }

        // TODO: Other slow ideas
    }

    onSelectFastIdeas(ideas: (IdeaDTO<ScannerPlanDTO> | IdeaDTO<SearchPlanDTO>)[]): void {
        this.#fastIdeas.value = ideas;

        // Reinitialize the query function to pick up any changed symbols from the ideas
        this.#initQueryFunction();
    }

    onSelectSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    openChartForSymbol(symbol: string) {
        this.store.dispatch(
            createChartFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    openLensForSymbol(symbol: string) {
        this.store.dispatch(
            createAlphaLensFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    // Method to request more historical events for infinite scrolling
    fetchMoreEvents() {
        if (this.#events.value.length === 0) {
            this.#logger.debug('No events to fetch more from');
            return;
        }

        // Get the oldest event we have so far
        const oldestEvent = this.#events.value[this.#events.value.length - 1];
        this.#logger.debug('Fetching more events', {
            oldestEventId: oldestEvent.eventId,
            oldestEventDate: oldestEvent.exDate.toString(),
        });

        // Ask the chronological query to load more data
        // Use moveToNextPage to get the next set of historical data
    }

    #resetState() {
        if (this.#state.type === 'initializing') {
            return;
        }

        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, null));
        this.store.dispatch(userSetEventsGoToDate(this.widgetData.tabId, null));

        // Go back to live mode

        // No need to manually update tracked symbols
        // The effect will automatically update tracked symbols based on tableSymbols
        // since symbolFilter was just set to null
    }

    #state: EventsWidgetModelState;

    @reacts private set state(newState: EventsWidgetModelState) {
        this.#state = newState;
    }

    get symbolFilter() {
        return this.widget.symbolFilter ?? '';
    }

    set symbolFilter(value: string) {
        this.store.dispatch(userSetEventsSymbolFilter(this.widgetData.tabId, value === '' ? null : value));

        // No need to manually update tracked symbols
        // The effect will automatically update when symbolFilter changes via this.#trackedSymbols
    }

    // Track symbols from table snapshots
    #tableSymbols = signal<string[]>([]);

    // For external access to table symbols
    get symbols() {
        return this.#tableSymbols as ReadonlySignal<string[]>;
    }

    // Computed signal that determines the actual tracked symbols based on filter and table data
    #trackedSymbols = computed(() => {
        if (this.symbolFilter) {
            return [this.symbolFilter];
        }
        return this.#tableSymbols.value;
    });

    // Track event types
    #eventTypes = signal<EventType[]>([]);

    get eventTypes() {
        return this.#eventTypes.value;
    }

    // Expose events from chronological query as a signal
    #events = signal<Event[]>([]);

    get events() {
        return this.#events as ReadonlySignal<Event[]>;
    }

    // Create the query function signal for the InfiniteTimeView
    #eventsQueryFnSignal = signal<QueryFn<EventWithSymbol>>(async (_options, _signal) => {
        // Default implementation - will be updated in init
        return { items: [] };
    });

    get eventsQueryFn() {
        return this.#eventsQueryFnSignal;
    }

    // Initialize the query function
    #initQueryFunction() {
        // Create the actual query function that will be used by the InfiniteTimeView
        const queryFn: QueryFn<EventWithSymbol> = async (
            options: {
                page: Page<EventWithSymbol>;
                previousPage?: Page<EventWithSymbol>;
                nextPage?: Page<EventWithSymbol>;
            },
            signal: AbortSignal,
        ) => {
            // Determine direction and reference based on pages
            let referenceId: string | undefined = undefined;

            // If this isn't the first page, use the last event from the previous page
            // as our reference point
            if (options.previousPage && options.previousPage.items.length > 0) {
                referenceId = options.previousPage.items[options.previousPage.items.length - 1].eventId;
            }

            const eventsResult = await getEvents({
                symbols: this.#trackedSymbols.value.length === 0 ? undefined : this.#trackedSymbols.value,
                eventTypes: this.#eventTypes.value,
                end: referenceId ? undefined : (this.widget.gotoDate ?? LocalDate.now().plusDays(1)),
                referenceId,
                count: 100,
                abortSignal: signal,
            });

            // Transform to EventWithSymbol for compatibility with SymbolRow interface
            const transformedEvents: EventWithSymbol[] = eventsResult.map((event) => ({
                ...event,
                symbols: [event.symbol], // Map the symbol to symbols array
                key: event.eventId, // Use eventId as the key
            }));

            return {
                items: transformedEvents,
                // Determine if we've reached the end of data
                isEndOfDataForward: transformedEvents.length === 0,
                isEndOfDataBackward: options.page.index === 0,
            };
        };

        // Update the signal with our new query function
        this.#eventsQueryFnSignal.value = queryFn;
    }

    get universe() {
        return this.widget.universeId;
    }

    set universe(universe: string | null) {
        this.#resetState();
        this.store.dispatch(userSetEventsUniverse(this.widgetData.tabId, universe));

        // When universe changes, wait for the table to update with new symbols
        // The table polling logic will then update the tracked symbols
        // We don't need to do anything here as the timer will pick up the new symbols
    }

    #userSelectedUniverseId: Signal<string | null> = signal(null);

    get widget() {
        return this.widgetData.widget as EventsWidgetViewModel;
    }
}
