/* eslint eslint-comments/no-unlimited-disable: off */
/* eslint-disable */
// This document was generated automatically by openapi-box

/**
 * @typedef {import('@sinclair/typebox').TSchema} TSchema
 */

/**
 * @template {TSchema} T
 * @typedef {import('@sinclair/typebox').Static<T>} Static
 */

/**
 * @typedef {import('@sinclair/typebox').SchemaOptions} SchemaOptions
 */

/**
 * @typedef {{
 *  [Path in keyof typeof schema]: {
 *    [Method in keyof typeof schema[Path]]: {
 *      [Prop in keyof typeof schema[Path][Method]]: typeof schema[Path][Method][Prop] extends TSchema ?
 *        Static<typeof schema[Path][Method][Prop]> :
 *        undefined
 *    }
 *  }
 * }} SchemaType
 */

/**
 * @typedef {{
 *  [ComponentType in keyof typeof _components]: {
 *    [ComponentName in keyof typeof _components[ComponentType]]: typeof _components[ComponentType][ComponentName] extends TSchema ?
 *      Static<typeof _components[ComponentType][ComponentName]> :
 *      undefined
 *  }
 * }} ComponentType
 */

import { Type as T, TypeRegistry, Kind, CloneType } from '@sinclair/typebox'
import { Value } from '@sinclair/typebox/value'

/**
 * @typedef {{
 *  [Kind]: 'Binary'
 *  static: string | File | Blob | Uint8Array
 *  anyOf: [{
 *    type: 'object',
 *    additionalProperties: true
 *  }, {
 *    type: 'string',
 *    format: 'binary'
 *  }]
 * } & TSchema} TBinary
 */

/**
 * @returns {TBinary}
 */
const Binary = () => {
  /**
   * @param {TBinary} schema
   * @param {unknown} value
   * @returns {boolean}
   */
  function BinaryCheck(schema, value) {
    const type = Object.prototype.toString.call(value)
    return (
      type === '[object Blob]' ||
      type === '[object File]' ||
      type === '[object String]' ||
      type === '[object Uint8Array]'
    )
  }

  if (!TypeRegistry.Has('Binary')) TypeRegistry.Set('Binary', BinaryCheck)

  return /** @type {TBinary} */ ({
    anyOf: [
      {
        type: 'object',
        additionalProperties: true
      },
      {
        type: 'string',
        format: 'binary'
      }
    ],
    [Kind]: 'Binary'
  })
}

const ComponentsSchemasExampleQueriesForType = T.Object({
  type: T.String(),
  queries: T.Array(T.String())
})
const ComponentsSchemasAlphabotExamplesResponse = T.Object({
  example_queries: T.Array(CloneType(ComponentsSchemasExampleQueriesForType))
})
const ComponentsSchemasValidationError = T.Object({
  loc: T.Array(T.Union([T.String(), T.Integer()])),
  msg: T.String(),
  type: T.String()
})
const ComponentsSchemasHttpValidationError = T.Object({
  detail: T.Optional(T.Array(CloneType(ComponentsSchemasValidationError)))
})
const ComponentsSchemasTextPayload = T.Object({
  type: T.Optional(T.Literal('text', { default: 'text' })),
  currentText: T.String()
})
const ComponentsSchemasChartTimeframe = T.Object({
  start: T.String(),
  end: T.Optional(T.Union([T.String(), T.Null()]))
})
const ComponentsSchemasFormulaImport = T.Object({
  alias: T.String(),
  version: T.Integer(),
  id: T.String()
})
const ComponentsSchemasCustomChartTrace = T.Object({
  formula: T.String(),
  imports: T.Array(CloneType(ComponentsSchemasFormulaImport)),
  periodicity: T.Union([
    T.Literal('10s'),
    T.Literal('30s'),
    T.Literal('1m'),
    T.Literal('5m'),
    T.Literal('1d'),
    T.Literal('1w'),
    T.Literal('1y')
  ]),
  includedKey: T.String(),
  chartType: T.Union([T.Literal('line'), T.Literal('bar')])
})
const ComponentsSchemasDefinedChartTrace = T.Object({
  periodicity: T.Union([
    T.Literal('10s'),
    T.Literal('30s'),
    T.Literal('1m'),
    T.Literal('5m'),
    T.Literal('1d'),
    T.Literal('1w'),
    T.Literal('1y')
  ]),
  includedKey: T.String(),
  chartType: T.Union([T.Literal('candlestick'), T.Literal('ohlc')])
})
const ComponentsSchemasChartApiIncoming = T.Object({
  key: T.Literal('ticker'),
  timeframe: CloneType(ComponentsSchemasChartTimeframe),
  result: T.Array(
    T.Union([
      CloneType(ComponentsSchemasCustomChartTrace),
      CloneType(ComponentsSchemasDefinedChartTrace)
    ])
  )
})
const ComponentsSchemasChartPayload = T.Object({
  type: T.Optional(T.Literal('chart', { default: 'chart' })),
  result: CloneType(ComponentsSchemasChartApiIncoming),
  queryStr: T.String()
})
const ComponentsSchemasNlpApiResultField = T.Object({
  formula: T.String(),
  imports: T.Array(CloneType(ComponentsSchemasFormulaImport)),
  includedKey: T.Optional(T.Union([T.String(), T.Null()])),
  excludedKeys: T.Optional(T.Union([T.Array(T.String()), T.Null()]))
})
const ComponentsSchemasModifiedDetail = T.Object({
  reason: T.String(),
  from: T.String(),
  to: T.String()
})
const ComponentsSchemasSimilarResultField = T.Object({
  input: T.String(),
  formula: T.String(),
  score: T.Number(),
  implicit: T.String()
})
const ComponentsSchemasFormulaInfo = T.Object({
  name: T.String(),
  formula: T.String(),
  imports: T.Array(CloneType(ComponentsSchemasFormulaImport))
})
const ComponentsSchemasNlpApiIncoming = T.Object({
  docId: T.String(),
  key: T.Literal('ticker'),
  result: CloneType(ComponentsSchemasNlpApiResultField),
  modified: T.Union([CloneType(ComponentsSchemasModifiedDetail), T.Null()]),
  similar: T.Array(CloneType(ComponentsSchemasSimilarResultField)),
  clientId: T.Union([T.String(), T.Null()]),
  namedColumns: T.Union([
    T.Array(CloneType(ComponentsSchemasFormulaInfo)),
    T.Null()
  ]),
  benchmarkFormulae: T.Union([
    T.Array(CloneType(ComponentsSchemasFormulaInfo)),
    T.Null()
  ])
})
const ComponentsSchemasNlpTablePayload = T.Object({
  type: T.Union([T.Literal('query'), T.Literal('search')]),
  result: CloneType(ComponentsSchemasNlpApiIncoming),
  queryStr: T.String()
})
const ComponentsSchemasNlpSingleValuePayload = T.Object({
  type: T.Optional(T.Literal('value', { default: 'value' })),
  result: CloneType(ComponentsSchemasNlpApiIncoming),
  queryStr: T.String()
})
const ComponentsSchemasAlphabotWebsocketMessageBot = T.Object({
  payload: T.Union([
    CloneType(ComponentsSchemasTextPayload),
    CloneType(ComponentsSchemasChartPayload),
    CloneType(ComponentsSchemasNlpTablePayload),
    CloneType(ComponentsSchemasNlpSingleValuePayload)
  ]),
  done: T.Boolean(),
  conversation_id: T.String()
})
const ComponentsSchemasAlphabotWebsocketMessageUser = T.Object({
  request_type: T.Optional(T.String({ default: 'chat' })),
  query: T.String(),
  user_id: T.String(),
  conversation_id: T.Union([T.String(), T.Null()])
})
const ComponentsSchemasAlphabotWebsocketAuth = T.Object({
  authorization: T.String()
})
const ComponentsSchemasAlphabotNewConversationResponse = T.Object({
  conversation_id: T.String()
})
const ComponentsSchemasConversationInfo = T.Object({
  user_id: T.String(),
  conversation_id: T.String(),
  name: T.String(),
  creation_time: T.String({ format: 'date-time' })
})
const ComponentsSchemasAlphabotConversationListResponse = T.Object({
  user_id: T.String(),
  conversation_list: T.Array(CloneType(ComponentsSchemasConversationInfo))
})
const ComponentsSchemasAlphabotDeleteConversationResponse = T.Object({
  conversation_id: T.String()
})
const ComponentsSchemasConversationMessageDocument = T.Object({
  conversation_id: T.String(),
  role: T.Union([T.Literal('user'), T.Literal('assistant')]),
  message: T.Union([
    CloneType(ComponentsSchemasNlpTablePayload),
    CloneType(ComponentsSchemasNlpSingleValuePayload),
    CloneType(ComponentsSchemasChartPayload),
    CloneType(ComponentsSchemasTextPayload),
    T.String()
  ]),
  timestamp: T.String({ format: 'date-time' })
})
const ComponentsSchemasAlphabotConversationMessagesResponse = T.Object({
  user_id: T.String(),
  conversation_id: T.String(),
  conversation: T.Array(CloneType(ComponentsSchemasConversationMessageDocument))
})
const ComponentsSchemasFilingTypeResponse = T.Object({
  result: T.Array(T.String())
})
const ComponentsSchemasSearchRequest = T.Object({
  request_type: T.Optional(T.String({ default: 'search_edgar' })),
  symbols: T.Array(T.String()),
  keywords_or_phrase: T.Union([T.String(), T.Array(T.String())]),
  filing_types: T.Array(T.String())
})
const ComponentsSchemasSearchResponse = T.Object({
  result: T.Array(
    T.Object(
      {},
      {
        additionalProperties: {
          anyOf: [{ type: 'string' }, { type: 'integer' }]
        }
      }
    )
  )
})
const ComponentsSchemasSummarizeEdgarRequest = T.Object({
  request_type: T.Optional(T.String({ default: 'summarize_edgar' })),
  filing_id: T.String()
})
const ComponentsSchemasSummarizeEdgarResponse = T.Object({
  result: T.Object(
    {},
    {
      additionalProperties: T.String()
    }
  )
})
const ComponentsSchemasSummarizeNewsRequest = T.Object({
  request_type: T.Optional(T.String({ default: 'summarize_news' })),
  symbol_or_search_string: T.String(),
  query_type: T.Union([T.Literal('symbol'), T.Literal('search')]),
  from_timestamp: T.Union([T.String(), T.Null()]),
  to_timestamp: T.Union([T.String(), T.Null()])
})

const schema = {
  '/ping': {
    GET: {
      args: T.Void(),
      data: T.Any(),
      error: T.Union([T.Any({ 'x-status-code': 'default' })])
    }
  },
  '/chat/examples': {
    GET: {
      args: T.Optional(
        T.Object({
          headers: T.Optional(
            T.Object({
              Authorization: T.Optional(
                T.Union([T.String(), T.Null()], { 'x-in': 'header' })
              )
            })
          ),
          query: T.Optional(
            T.Object({
              user_id: T.Optional(
                T.Union([T.String(), T.Null()], { 'x-in': 'query' })
              )
            })
          )
        })
      ),
      data: CloneType(ComponentsSchemasAlphabotExamplesResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        CloneType(ComponentsSchemasHttpValidationError, {
          'x-status-code': '422',
          'x-content-type': 'application/json'
        }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/chat/websocket_types': {
    GET: {
      args: T.Optional(
        T.Object({
          headers: T.Optional(
            T.Object({
              Authorization: T.Optional(
                T.Union([T.String(), T.Null()], { 'x-in': 'header' })
              )
            })
          ),
          query: T.Optional(
            T.Object({
              user_id: T.Optional(
                T.Union([T.String(), T.Null()], { 'x-in': 'query' })
              )
            })
          )
        })
      ),
      data: T.Union(
        [
          CloneType(ComponentsSchemasAlphabotWebsocketMessageBot),
          CloneType(ComponentsSchemasAlphabotWebsocketMessageUser),
          CloneType(ComponentsSchemasAlphabotWebsocketAuth)
        ],
        { 'x-status-code': '200', 'x-content-type': 'application/json' }
      ),
      error: T.Union([
        CloneType(ComponentsSchemasHttpValidationError, {
          'x-status-code': '422',
          'x-content-type': 'application/json'
        }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/chat/{user_id}/new': {
    GET: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        params: T.Object({
          user_id: T.Union([T.String(), T.Null()], { 'x-in': 'path' })
        })
      }),
      data: CloneType(ComponentsSchemasAlphabotNewConversationResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/chat/{user_id}': {
    GET: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        params: T.Object({
          user_id: T.Union([T.String(), T.Null()], { 'x-in': 'path' })
        })
      }),
      data: CloneType(ComponentsSchemasAlphabotConversationListResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/chat/{user_id}/{conversation_id}/delete': {
    GET: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        params: T.Object({
          user_id: T.Union([T.String(), T.Null()], { 'x-in': 'path' }),
          conversation_id: T.String({ 'x-in': 'path' })
        })
      }),
      data: CloneType(ComponentsSchemasAlphabotDeleteConversationResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/chat/{user_id}/{conversation_id}': {
    GET: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        params: T.Object({
          user_id: T.Union([T.String(), T.Null()], { 'x-in': 'path' }),
          conversation_id: T.String({ 'x-in': 'path' })
        })
      }),
      data: CloneType(ComponentsSchemasAlphabotConversationMessagesResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/v1/filing_types': {
    GET: {
      args: T.Optional(
        T.Object({
          headers: T.Optional(
            T.Object({
              Authorization: T.Optional(
                T.Union([T.String(), T.Null()], { 'x-in': 'header' })
              )
            })
          ),
          query: T.Optional(
            T.Object({
              user_id: T.Optional(
                T.Union([T.String(), T.Null()], { 'x-in': 'query' })
              )
            })
          )
        })
      ),
      data: CloneType(ComponentsSchemasFilingTypeResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/v1/search': {
    POST: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        query: T.Optional(
          T.Object({
            user_id: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'query' })
            )
          })
        ),
        body: CloneType(ComponentsSchemasSearchRequest, {
          'x-content-type': 'application/json'
        })
      }),
      data: CloneType(ComponentsSchemasSearchResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/v1/summarize_edgar': {
    POST: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        query: T.Optional(
          T.Object({
            user_id: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'query' })
            )
          })
        ),
        body: CloneType(ComponentsSchemasSummarizeEdgarRequest, {
          'x-content-type': 'application/json'
        })
      }),
      data: CloneType(ComponentsSchemasSummarizeEdgarResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  },
  '/v1/summarize_news': {
    POST: {
      args: T.Object({
        headers: T.Optional(
          T.Object({
            Authorization: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'header' })
            )
          })
        ),
        query: T.Optional(
          T.Object({
            user_id: T.Optional(
              T.Union([T.String(), T.Null()], { 'x-in': 'query' })
            )
          })
        ),
        body: CloneType(ComponentsSchemasSummarizeNewsRequest, {
          'x-content-type': 'application/json'
        })
      }),
      data: CloneType(ComponentsSchemasSummarizeEdgarResponse, {
        'x-status-code': '200',
        'x-content-type': 'application/json'
      }),
      error: T.Union([
        T.Any({ 'x-status-code': '401' }),
        T.Any({ 'x-status-code': '422' }),
        T.Any({ 'x-status-code': '500' })
      ])
    }
  }
}

const _components = {
  schemas: {
    AlphabotConversationListResponse: CloneType(
      ComponentsSchemasAlphabotConversationListResponse
    ),
    AlphabotConversationMessagesResponse: CloneType(
      ComponentsSchemasAlphabotConversationMessagesResponse
    ),
    AlphabotDeleteConversationResponse: CloneType(
      ComponentsSchemasAlphabotDeleteConversationResponse
    ),
    AlphabotExamplesResponse: CloneType(
      ComponentsSchemasAlphabotExamplesResponse
    ),
    AlphabotNewConversationResponse: CloneType(
      ComponentsSchemasAlphabotNewConversationResponse
    ),
    AlphabotWebsocketAuth: CloneType(ComponentsSchemasAlphabotWebsocketAuth),
    AlphabotWebsocketMessageBot: CloneType(
      ComponentsSchemasAlphabotWebsocketMessageBot
    ),
    AlphabotWebsocketMessageUser: CloneType(
      ComponentsSchemasAlphabotWebsocketMessageUser
    ),
    ChartApiIncoming: CloneType(ComponentsSchemasChartApiIncoming),
    ChartPayload: CloneType(ComponentsSchemasChartPayload),
    ChartTimeframe: CloneType(ComponentsSchemasChartTimeframe),
    ConversationInfo: CloneType(ComponentsSchemasConversationInfo),
    ConversationMessageDocument: CloneType(
      ComponentsSchemasConversationMessageDocument
    ),
    CustomChartTrace: CloneType(ComponentsSchemasCustomChartTrace),
    DefinedChartTrace: CloneType(ComponentsSchemasDefinedChartTrace),
    ExampleQueriesForType: CloneType(ComponentsSchemasExampleQueriesForType),
    FilingTypeResponse: CloneType(ComponentsSchemasFilingTypeResponse),
    FormulaImport: CloneType(ComponentsSchemasFormulaImport),
    FormulaInfo: CloneType(ComponentsSchemasFormulaInfo),
    HTTPValidationError: CloneType(ComponentsSchemasHttpValidationError),
    ModifiedDetail: CloneType(ComponentsSchemasModifiedDetail),
    NlpApiIncoming: CloneType(ComponentsSchemasNlpApiIncoming),
    NlpApiResultField: CloneType(ComponentsSchemasNlpApiResultField),
    NlpSingleValuePayload: CloneType(ComponentsSchemasNlpSingleValuePayload),
    NlpTablePayload: CloneType(ComponentsSchemasNlpTablePayload),
    SearchRequest: CloneType(ComponentsSchemasSearchRequest),
    SearchResponse: CloneType(ComponentsSchemasSearchResponse),
    SimilarResultField: CloneType(ComponentsSchemasSimilarResultField),
    SummarizeEdgarRequest: CloneType(ComponentsSchemasSummarizeEdgarRequest),
    SummarizeEdgarResponse: CloneType(ComponentsSchemasSummarizeEdgarResponse),
    SummarizeNewsRequest: CloneType(ComponentsSchemasSummarizeNewsRequest),
    TextPayload: CloneType(ComponentsSchemasTextPayload),
    ValidationError: CloneType(ComponentsSchemasValidationError)
  }
}

export { schema, _components as components }
