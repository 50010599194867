import { rxapi, api } from '../api';
import { processQuery } from './paging';
import type { ConcreteIdea, NewIdea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { AxiosResponse } from 'axios';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { container } from 'src/StaticContainer';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import type { ConcreteStrategy, StrategyPut, StrategyRecord, TopStrategies } from 'src/contracts/strategy';

const _log = container.get('Logger').getSubLogger({ name: 'strategy-service' });

export const getStrategyRecordsQuery = async (
    query: ResourceQuery,
): Promise<ResourceQueryResponseWithMeta<StrategyRecord>> => {
    return (await api.get(`/ideas/query${processQuery(query)}`)).data;
};
export const getStrategyRecordsCountQuery = async (): Promise<ResourceQueryResponseWithMeta<StrategyRecord>> => {
    return (await api.get(`/ideas/query?$select=&$count=true`)).data;
};

export function getTopStrategyRecords(): Observable<TopStrategies> {
    return rxapi.get<TopStrategies>('/ideas/top').pipe(map((x) => x.data));
}

export function deleteStrategyById(id: string): Promise<AxiosResponse> {
    return api.delete(`/ideas/${id}`);
}

export function createStrategyWithMappedImports(strategy: NewIdea): Promise<ConcreteIdea> {
    return api.post<ConcreteIdea>('/ideas?$expand=imports', strategy).then((x) => x.data);
}

export function putStrategyP(id: string, strategySubset: StrategyPut): Promise<ConcreteStrategy> {
    return api.put<ConcreteStrategy>(`/ideas/${id}`, strategySubset).then((x) => x.data);
}
