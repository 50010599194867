import {
    getAlphaLensDefaultWidget,
    getDefaultScannerPlanId,
    getDefaultTopbarFormulas,
    getFormulasFromToolbarItems,
    getOrderEntryTickers,
    getPinnedUniverses,
    getSuggestedColumns,
    getToolbarItems,
} from './http/userPreference';
import { type QueryObserverOptions } from '@tanstack/query-core';
import type { DictionaryBackedData } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { type OrderEntryTicker } from '@thinkalpha/platform-ws-client/contracts/user-preference.js';
import type { TemplateColumnBase } from 'src/contracts/column-template';
import { type IndicatorFormulaViewModel } from 'src/contracts/dictionary-view-model';
import { type AlphaLensContent } from 'src/contracts/workspace';
import { ToolbarItemDTO } from 'src/features/toolbar/components/ToolbarWidgets/dtos/toolbarItemDTO';

// Constants
export const USER_PREFERENCES = 'user-preferences';
export const PINNED_UNIVERSES = 'pinned-universes';
export const TOOLBAR_ITEMS = 'toolbar-items';
export const ALPHA_LENS_WIDGET_MODEL_DEFAULT = 'alpha-lens-widget-model-default';
export const DEFAULT_TOPBAR_FORMULAS = 'default-topbar-formulas';
export const DEFAULT_FORMULAS_TOOLBAR_ITEMS = 'default-formulas-toolbar-items';
export const ORDER_ENTRY_TICKERS = 'order-entry-tickers';
export const SUGGESTED_COLUMNS = 'suggested-columns';
export const DEFAULT_SCANNER_PLAN_ID = 'default-scanner-plan-id';
/**
 * Get pinned universe ids for a user.
 */
export function getPinnedUniversesQuery(): QueryObserverOptions<string[]> {
    return {
        queryKey: [USER_PREFERENCES, PINNED_UNIVERSES],
        queryFn: () => getPinnedUniverses(),
    };
}

/**
 * Get default scanner plan ID for a user.
 */
export function getDefaultScannerPlanIdQuery(): QueryObserverOptions<string> {
    return {
        queryKey: [USER_PREFERENCES, DEFAULT_SCANNER_PLAN_ID],
        queryFn: () => getDefaultScannerPlanId(),
    };
}

/**
 * Get toolbar items for a user.
 */
export function getToolbarItemsQuery({ enabled = true }: { enabled?: boolean } = {}): QueryObserverOptions<
    ToolbarItemDTO[]
> {
    return {
        queryKey: [USER_PREFERENCES, TOOLBAR_ITEMS],
        queryFn: async () => {
            const items = await getToolbarItems();
            return items.map((item) => ToolbarItemDTO.from(item));
        },
        enabled,
    };
}

export function getAlphaLensDefaultWidgetQuery(): QueryObserverOptions<AlphaLensContent> {
    return {
        queryKey: [USER_PREFERENCES, ALPHA_LENS_WIDGET_MODEL_DEFAULT],
        queryFn: () => getAlphaLensDefaultWidget(),
    };
}

export function getDefaultTopbarFormulasQuery(): QueryObserverOptions<DictionaryBackedData[]> {
    return {
        queryKey: [USER_PREFERENCES, DEFAULT_TOPBAR_FORMULAS],
        queryFn: () => getDefaultTopbarFormulas(),
    };
}

export function getFormulasFromToolbarItemsQuery(
    defaultFormulas?: DictionaryBackedData[],
): QueryObserverOptions<IndicatorFormulaViewModel[]> {
    return {
        queryFn: () => getFormulasFromToolbarItems(defaultFormulas ?? []),
        queryKey: [USER_PREFERENCES, DEFAULT_FORMULAS_TOOLBAR_ITEMS, JSON.stringify(defaultFormulas ?? [])],
    };
}

export function getOrderEntryTickersQuery(): QueryObserverOptions<OrderEntryTicker[]> {
    return {
        queryFn: () => getOrderEntryTickers(),
        queryKey: [USER_PREFERENCES, ORDER_ENTRY_TICKERS],
    };
}

export function getSuggestedColumnsQuery(suggestedColumnsKey?: string): QueryObserverOptions<TemplateColumnBase[]> {
    return {
        queryKey: [USER_PREFERENCES, SUGGESTED_COLUMNS, suggestedColumnsKey],
        queryFn: () => getSuggestedColumns(suggestedColumnsKey ?? ''),
        enabled: !!suggestedColumnsKey,
    };
}
