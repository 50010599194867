import { getDictionaryTags } from './http/dictionary';
import type { FetchQueryOptions, QueryObserverOptions } from '@tanstack/query-core';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { ConcreteIndicator, IndicatorRef, Tag } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type { ConcreteIndicatorViewModel } from 'src/contracts/dictionary-view-model';
import { type ResourceQuery } from 'src/contracts/resource-query';
import { indicatorFromRefLoader, getIndicatorsQuery, getIndicatorsViewModelQuery } from 'src/lib/dictionary/dictionary';

// Constants
export const DICTIONARY = 'dictionary';

export const getDictionaryTagsQuery = (): UseQueryOptions<Tag[]> => {
    return {
        queryKey: [DICTIONARY, 'tags'],
        queryFn: async () => getDictionaryTags(),
    };
};

export function getIndicatorCompletionOptionsViewModelQuery({
    query,
    resourceQuery,
}: {
    query?: string | null;
    resourceQuery?: ResourceQuery;
}): FetchQueryOptions<ConcreteIndicatorViewModel[]> {
    return {
        queryKey: [DICTIONARY, 'view models', query, resourceQuery],
        queryFn: async () => {
            const res = await getIndicatorsViewModelQuery({ search: query ?? undefined, ...resourceQuery });
            return res;
        },
    };
}

export function getIndicatorCompletionOptionsQuery({
    query,
    resourceQuery,
}: {
    query?: string | null;
    resourceQuery?: ResourceQuery;
}): FetchQueryOptions<ConcreteIndicator[]> {
    return {
        queryKey: [DICTIONARY, query, resourceQuery],
        queryFn: async () => {
            const res = await getIndicatorsQuery({ search: query ?? undefined, ...resourceQuery });
            return res;
        },
    };
}

export const getIndicatorFromRefQuery = (ref?: IndicatorRef): QueryObserverOptions<ConcreteIndicator> => {
    return {
        queryKey: [DICTIONARY, ref?.id, ref?.version],
        queryFn: () => indicatorFromRefLoader.load(ref!),
        enabled: Boolean(ref),
    };
};
