import {
    ChartPayload,
    TextPayload,
    NlpTablePayload,
    NlpSingleValuePayload,
    ConversationMessageDocument,
    AlphabotWebsocketMessageBot,
} from '../types';
import { Instant } from '@js-joda/core';
import { Value } from '@sinclair/typebox/value';
import { container } from 'src/StaticContainer';
import { replacer, reviver } from 'src/lib/serializer';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';

const log = container.get('Logger').getSubLogger({ name: 'feat:chatbot:MessageDocument' });

interface BaseMessageDocument {
    done: boolean;
    timestamp: string;
    conversationId: string;
    role: 'user' | 'assistant';
}

export interface TextMessageDocument extends BaseMessageDocument {
    type: 'text';
    message: TextPayload;
}

export interface ChartMessageDocument extends BaseMessageDocument {
    type: 'chart';
    message: ChartPayload;
}

export interface NlpTableMessageDocument extends BaseMessageDocument {
    type: 'query' | 'search';
    message: NlpTablePayload;
}

export interface NlpSingleValueMessageDocument extends BaseMessageDocument {
    type: 'value';
    message: NlpSingleValuePayload;
}

export interface ErrorMessageDocument extends BaseMessageDocument {
    type: 'error';
    message: TextPayload;
}

/**
 * This is here so that we can differentiate between
 * an error message constructor payload, and TestMessageDocument
 *
 * Typebox sees a message of type string, and assumes we have a TextMessage
 */
export interface IncomingErrorMessageDocument extends BaseMessageDocument {
    type: 'error';
    errorMessage: string;
}

interface MessageDocumentInterface {
    done: boolean;
    conversationId: string;
    role: 'user' | 'assistant';
    timestamp: Instant;
    message: TextPayload | ChartPayload | NlpTablePayload | NlpSingleValuePayload | ErrorMessageDocument;
    type: 'text' | 'chart' | 'query' | 'search' | 'value' | 'error';
}

export class MessageDocument implements MessageDocumentInterface {
    #done: boolean;
    #conversationId: string;
    #role: 'user' | 'assistant';
    #timestamp: Instant;
    #message: TextPayload | ChartPayload | NlpTablePayload | NlpSingleValuePayload;
    #type: 'text' | 'chart' | 'query' | 'search' | 'value' | 'error';

    constructor(message: AlphabotWebsocketMessageBot | ConversationMessageDocument | IncomingErrorMessageDocument) {
        // because we are using a custom deserializer at the API layer,
        //  the message from the API has things like timeStamps
        // converted from strings, to instances of Instant
        // we need to convert back to a RAW message in order for the typebox parser/checker to work
        message = JSON.parse(JSON.stringify(message, replacer)) as
            | AlphabotWebsocketMessageBot
            | ConversationMessageDocument
            | IncomingErrorMessageDocument;

        if (Value.Check(AlphabotWebsocketMessageBot, message)) {
            this.#done = message.done;
            this.#conversationId = message.conversation_id;
            this.#role = 'assistant';
            this.#timestamp = Instant.now();

            const parsedMessage = this.#parseMessage(message.payload);
            this.#message = parsedMessage.message;
            this.#type = parsedMessage.type;

            return;
        }

        if (Value.Check(ConversationMessageDocument, message)) {
            this.#done = true;
            this.#conversationId = message.conversation_id;
            this.#role = message.role;
            // bit of a song and dance, now we need to convert the timestamp back to an Instant
            this.#timestamp = reviver('_key', message.timestamp);

            const parsedMessage = this.#parseMessage(message.message);
            this.#message = parsedMessage.message;
            this.#type = parsedMessage.type;

            return;
        }

        if (message.type === 'error') {
            this.#done = message.done;
            this.#conversationId = message.conversationId;
            this.#role = message.role;
            this.#timestamp = Instant.now();
            this.#type = 'error';
            this.#message = {
                type: 'text',
                currentText: message.errorMessage,
            };

            return;
        }
        // message is for-sure "never" at this point
        // however TS can't infer it so we need to cast it
        // this may be a problem in the future if we except another type of message
        // the unreachable case error is not going to have our back here
        // but we still need to throw at this point so TS can infer we have handled all cases
        // and all the private variables are initialized
        try {
            log.error({
                message: 'unknown message',
                ctx: { message, errors: [...Value.Errors(AlphabotWebsocketMessageBot, message)] },
            });
        } catch (error) {
            log.error('unknown message, failed to parse message', { error });
        } finally {
            throw new UnreachableCaseError(message as never);
        }
    }

    #parseMessage(message: TextPayload | ChartPayload | NlpTablePayload | NlpSingleValuePayload | string): {
        type: 'text' | 'chart' | 'query' | 'search' | 'value' | 'error';
        message: TextPayload | ChartPayload | NlpTablePayload | NlpSingleValuePayload;
    } {
        if (typeof message === 'string') {
            return {
                type: 'text',
                message: {
                    type: 'text',
                    currentText: message,
                } as TextPayload,
            };
        }

        if (Value.Check(TextPayload, message)) {
            return { type: 'text', message };
        }

        if (Value.Check(ChartPayload, message)) {
            return { type: 'chart', message };
        }

        if (Value.Check(NlpTablePayload, message)) {
            return { type: 'query', message };
        }

        if (Value.Check(NlpSingleValuePayload, message)) {
            return { type: 'value', message };
        }

        log.error('#parseMessage, unknown message', { message });

        throw new UnreachableCaseError(message);
    }

    get done() {
        return this.#done;
    }

    get conversationId() {
        return this.#conversationId;
    }

    get role() {
        return this.#role;
    }

    get timestamp() {
        return this.#timestamp;
    }

    get message() {
        return this.#message;
    }

    get type() {
        return this.#type;
    }

    toJSON() {
        return {
            done: this.#done,
            conversationId: this.#conversationId,
            role: this.#role,
            timestamp: this.#timestamp,
            message: this.#message,
            type: this.#type,
        };
    }
}

// guard methods to help with type narrowing
export function isTextMessage(doc: MessageDocument): doc is MessageDocument & TextMessageDocument {
    return doc.type === 'text';
}

export function isChartMessage(doc: MessageDocument): doc is MessageDocument & ChartMessageDocument {
    return doc.type === 'chart';
}

export function isNlpTableMessage(doc: MessageDocument): doc is MessageDocument & NlpTableMessageDocument {
    return doc.type === 'query' || doc.type === 'search';
}

export function isNlpSingleValueMessage(doc: MessageDocument): doc is MessageDocument & NlpSingleValueMessageDocument {
    return doc.type === 'value';
}

export function isErrorMessage(doc: MessageDocument): doc is MessageDocument & ErrorMessageDocument {
    return doc.type === 'error';
}
