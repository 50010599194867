import type { DefaultUniverseService } from '.';
import { effect, signal } from '@preact/signals-react';
import { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { injectable, inject } from 'src/features/ioc';
import { getDefaultUniverseRecordQuery } from 'src/queries/universes';
import { ReactBindings } from 'src/types/bindings';

@injectable()
export class DefaultUniverseServiceImpl implements DefaultUniverseService {
    constructor(
        @inject('QueryObserverModelFactory') queryObserverModelFactory: ReactBindings['QueryObserverModelFactory'],
    ) {
        const query = queryObserverModelFactory(getDefaultUniverseRecordQuery());

        query.subscribe((result) => {
            if (result.isSuccess) {
                this.universe.value = result.data;
            }
        });
    }

    async initialize(): Promise<void> {
        if (this.universe.value) {
            return;
        }

        using stack = new DisposableStack();

        await new Promise<void>((resolve) => {
            stack.defer(
                effect(() => {
                    if (this.universe.value) {
                        resolve();
                    }
                }),
            );
        });
    }

    universe = signal<Universe>(null as any);
}
