import { delayUntil } from '../utils';
import {
    clearStoredAccessToken,
    clearStoredMasqToken,
    clearStoredRefreshToken,
    storeAccessToken,
    storeMasqToken,
    storeRefreshToken,
} from './helpers';
import type { OAuth2UserInfoPayload } from './oAuth2UserInfo';
import { listenForCallback } from './oauth2Callback';
import { Duration, Instant } from '@js-joda/core';
import axios, { AxiosError } from 'axios';
import { push } from 'redux-first-history';
import { all, call, getContext, put, race, spawn, take, takeLatest } from 'redux-saga/effects';
import { container } from 'src/StaticContainer';
import { PERSISTED_CURRENT_USER } from 'src/features/local-storage';
import { decodeToken, getExpirationDate } from 'src/features/oauth2';
import { localforage } from 'src/lib/serialization/localForage';
import { type RootState } from 'src/store';
import type { ClearTokenAction, SetTokenAction } from 'src/store/actions/auth';
import {
    setUserDetails,
    setMasqUserDetails,
    clearMasq,
    clearAccessToken,
    clearRefreshToken,
} from 'src/store/actions/auth';
import { select } from 'src/store/effects';
import type { TokenState } from 'src/store/reducers/auth';
import type { SagaContext, UserDetails } from 'src/store/types';

const log = container.get('Logger').getSubLogger({ name: 'auth sagas' });
const refreshMargin = Duration.ofSeconds(10);

export function* setAccessTokenSaga({ type, token, isInitial }: SetTokenAction) {
    log.debug({ message: 'Using access token', token });

    let userDetailsSetter: typeof setUserDetails;
    if (type === 'access-token::set') {
        if (!isInitial) {
            yield storeAccessToken(token);
        }
        yield localforage.removeItem(PERSISTED_CURRENT_USER);
        userDetailsSetter = setUserDetails;
    } else if (type === 'masq::access-token::set') {
        if (!isInitial) {
            yield storeMasqToken(token);
        }
        userDetailsSetter = setMasqUserDetails;
    } else if (type === 'refresh-token::set') {
        if (!isInitial) {
            yield storeRefreshToken(token);
        }
        return; // there's no user details for refresh tokens, so we can stop here
    } else {
        throw new Error('Unexpected token type');
    }

    if (token) {
        const decoded = decodeToken(token.token);
        try {
            const configService: SagaContext['configService'] = yield getContext('configService');
            const userInfoURL = configService.oAuthConfig.userInfoURL;

            const userResponse: { data: OAuth2UserInfoPayload } = yield call(axios.get, userInfoURL, {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            });

            if (TRACE) {
                log.trace({ message: 'User details response', userResponse });
            }

            if (userResponse?.data) {
                const userDetails: UserDetails = {
                    id: decoded.userId,
                    email: userResponse.data.email,
                    isSuperuser: decoded.isSuperuser,
                    isDeveloper: decoded.isDeveloper,
                    firstName: userResponse.data.given_name,
                    lastName: userResponse.data.family_name,
                };

                if (TRACE) {
                    log.trace({ message: 'Got user details', userDetails });
                }

                yield put(userDetailsSetter(userDetails));
            } else {
                if (TRACE) {
                    log.trace({ message: 'No user details found' });
                }

                yield put(userDetailsSetter(undefined));
            }
        } catch (e) {
            // Check if the user's token is not valid
            if (e instanceof AxiosError && e.status === 401) {
                log.info({ message: 'User is not authenticated' });

                if (TRACE) {
                    log.trace({ message: 'Redirecting to logout' });
                }

                // Redirect to logout -- In the future it would be nice to preserve state of the user's current location
                yield put({
                    ...push({ pathname: '/logout' }),
                    isIntentConfirmed: true,
                });
                return;
            } else if (e instanceof Error) {
                log.error({ message: 'Error authenticating user', error: e });
            } else {
                log.fatal({ message: 'Unknown exception', details: e });
            }
            yield put(userDetailsSetter(undefined));
        }
    } else {
        // put user undefined
        yield put(userDetailsSetter(undefined));
    }
}

export function* killMasqOnTimeout({ token }: SetTokenAction) {
    if (TRACE) {
        log.trace({ message: 'killMasqOnTimeout', token });
    }

    if (!token) return;

    log.debug({ message: 'Using masquerade access token', token });

    const exp = getExpirationDate(token.token);
    if (!exp) return;

    const {
        cleared,
        replaced,
    }: { delayed: true | undefined; cleared: ClearTokenAction | undefined; replaced: SetTokenAction | undefined } =
        yield race({
            delayed: delayUntil(exp),
            replaced: take('masq::access-token::set'),
            cleared: take('masq::clear'),
        });
    if (cleared || replaced) {
        // no need to renew if it was cleared
        // also, no need for THIS timeout if changed, a new saga will be started
        return;
    }

    yield put(clearMasq('Masquerade session expired'));
}

function* renewAccessTokenSaga({ token, type }: SetTokenAction) {
    if (!token?.expires) {
        log.debug({ message: "access token never expires, don't need to refresh it" });
        return;
    }

    const { expires: exp } = token;

    // Quick and temporary short-circuit
    if (type === 'access-token::set') {
        const now = Instant.now();
        const hasExpired = exp.isBefore(now);
        if (hasExpired) {
            // We probably shouldn't get this far, but yet here we are.
            if (TRACE) {
                log.trace({ message: 'renewAccessTokenSaga hasExpired' });
            }
            yield call(onLogoutEvent);
            return;
        }
    }

    const refreshAt = exp.minus(refreshMargin);

    const refreshToken: TokenState = yield select((store) => store.auth.refreshToken);
    if (!refreshToken || refreshToken.expires?.isBefore(refreshAt)) {
        log.debug({ message: 'refresh token expired or not present' });
        return;
    }

    const wait = Duration.between(Instant.now(), refreshAt);
    log.info({ message: `Will renew access token in ${wait.seconds()} seconds.` });

    const {
        changed,
    }: { delayed: true | undefined; cleared: ClearTokenAction | undefined; changed: SetTokenAction | undefined } =
        yield race({
            delayed: delayUntil(refreshAt),
            cleared: take('access-token::clear'),
            changed: take('access-token::set'),
        });
    if (changed) {
        // no need to renew if it was cleared
        // also, no need for THIS renewal if changed, a new saga will be started
        return;
    }

    // Temporarily: just logout on refresh expire
    const location: RootState['router']['location'] = yield select((store) => store.router.location);
    if (TRACE) {
        log.trace({ message: 'renewAccessTokenSaga refresh token expired', location });
    }

    if (location?.pathname !== '/logout') {
        yield call(onLogoutEvent);
    }

    // refreshToken = yield select((store) => store.auth.refreshToken);
    // if (!refreshToken) return; // refresh token was cleared, can't refresh

    // const oauth2: OAuth2Service = yield getContext('oAuth2Client');
    // try {
    //     const renewedToken: Awaited<ReturnType<typeof oauth2.renewToken>> = yield call(
    //         (token) => oauth2.renewToken(token),
    //         refreshToken.token,
    //     );
    //     log.info('Token renewed.');
    //     yield put(setAccessToken(renewedToken.accessToken));
    // } catch (e) {
    //     log.warn('Failed to renew token', e);
    //     yield put(clearAccessToken('Failed to renew refresh token'));
    //     yield put(clearRefreshToken('Failed to renew refresh token'));
    //     oauth2.startOauth();
    // }
}

export function* clearAccessTokenSaga({ reason }: ClearTokenAction) {
    log.info({ message: 'Clearing access token: ', reason });
    yield call(clearStoredAccessToken);
}

export function* clearRefreshTokenSaga({ reason }: ClearTokenAction) {
    log.info({ message: 'Clearing refresh token: ', reason });
    yield call(clearStoredRefreshToken);
}

export function* clearMasqTokenSaga({ reason }: ClearTokenAction) {
    log.info({ message: 'Clearing masquerade access token: ', reason });
    yield call(clearStoredMasqToken);
}

export function* onLogoutEvent() {
    try {
        const accessToken: string | undefined = yield select((store) => store.auth.accessToken?.token ?? undefined);
        const idToken: string | undefined = yield select((store) => store.auth.idToken?.token ?? undefined);
        const refreshToken: string | undefined = yield select((store) => store.auth.refreshToken?.token ?? undefined);

        const loginScreenService: SagaContext['loginScreenService'] = yield getContext('loginScreenService');
        // May be undefined in SDK
        loginScreenService?.showLoggedOutScreen();

        // Don't actually change the state, because we don't want to trigger UI to toss us to anonymous
        // mode until we recode this

        // TODO: This may not be necessary anymore, as we log out via redirect
        yield all([
            put(clearAccessToken('loggedOut')),
            put(clearRefreshToken('loggedOut')),
            put(clearMasq('loggedOut')),
        ]);

        const oauth2: SagaContext['oAuth2Client'] = yield getContext('oAuth2Client');
        if (idToken && accessToken) {
            yield call(() => oauth2.logout({ idToken, accessToken, refreshToken }));
        }
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: e.message, error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

export function* authSagas() {
    yield all([
        takeLatest<SetTokenAction['type']>('access-token::set', setAccessTokenSaga),
        takeLatest<SetTokenAction['type']>('refresh-token::set', setAccessTokenSaga),
        takeLatest<SetTokenAction['type']>('masq::access-token::set', setAccessTokenSaga),

        takeLatest<SetTokenAction['type']>('access-token::set', renewAccessTokenSaga),
        takeLatest<SetTokenAction['type']>('masq::access-token::set', killMasqOnTimeout),

        takeLatest<ClearTokenAction['type']>('access-token::clear', clearAccessTokenSaga),
        takeLatest<ClearTokenAction['type']>('refresh-token::clear', clearRefreshTokenSaga),
        takeLatest<ClearTokenAction['type']>('masq::clear', clearMasqTokenSaga),

        // Ensure that the callback handler runs on initial load
        spawn(listenForCallback),
    ]);
}
