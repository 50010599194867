import { notUndefined } from '@thinkalpha/common/util/notNullOrUndefined.js';
import type { DictionaryBackedData } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type {
    OrderEntryTicker,
    ToolbarItem,
    NewToolbarItem,
} from '@thinkalpha/platform-ws-client/contracts/user-preference.js';
import { container } from 'src/StaticContainer';
import { api } from 'src/api';
import type { TemplateColumnBase } from 'src/contracts/column-template';
import { type IndicatorFormulaViewModel, type IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import { type AlphaLensContent } from 'src/contracts/workspace';
import { getIndicatorsByIdsWithVersions } from 'src/lib/dictionary/dictionary';

const log = container.get('Logger').getSubLogger({ name: 'api-user-preferences' });

export function getAlphaLensDefaultWidget(): Promise<AlphaLensContent> {
    return api.get(`/user-preferences/alpha-lens-default`).then((res) => res.data);
}

export function getToolbarItems(): Promise<ToolbarItem[]> {
    return api.get(`/user-preferences/toolbar/items`).then((res) => res.data);
}

export function updateToolbarItemsList({ payload }: { payload: NewToolbarItem[] }) {
    return api.post(`/user-preferences/toolbar/items`, payload);
}

export function getDefaultTopbarFormulas(): Promise<DictionaryBackedData[]> {
    return api.get('/user-preferences/topbar/default-formulas').then((res) => res.data);
}

// workspace
export function setDefaultWorkspaceId(workspaceId: string): Promise<string> {
    return api.post(`/user-preferences/default-workspace`, { workspaceId }).then((res) => res.data);
}

// Order Entry
export function getOrderEntryTickers(): Promise<OrderEntryTicker[]> {
    return api.get(`/user-preferences/order-entry/tickers`).then((res) => res.data);
}

export async function getPinnedUniverses(): Promise<string[]> {
    return (await api.get(`/user-preferences/pinned-universes`)).data;
}

export async function getSuggestedColumns(key: string): Promise<TemplateColumnBase[]> {
    return (await api.get(`/user-preferences/suggested-columns`, { params: { key } })).data;
}

export async function getDefaultScannerPlanId(): Promise<string> {
    return (await api.get(`/user-preferences/default-scanner-plan-id`)).data;
}

export async function getFormulasFromToolbarItems(
    items: DictionaryBackedData[],
): Promise<IndicatorFormulaViewModel<IndicatorImportViewModel>[]> {
    const indicatorsToFetch: string[] = items.reduce((acc, current) => {
        if (current.type === 'indicator') {
            return [...acc, current.indicator.id];
        } else {
            return [...acc, ...current.imports.map((x) => x.id)];
        }
    }, []);

    const indicators = await getIndicatorsByIdsWithVersions(indicatorsToFetch);

    const formulaViewModels: IndicatorFormulaViewModel<IndicatorImportViewModel>[] = [];

    for (const item of items) {
        if (item.type === 'indicator') {
            const indicator = indicators.find(
                (x) => x.id === item.indicator.id && x.version === item.indicator.version,
            );
            if (!indicator) {
                throw new Error(`Indicator with id ${item.indicator.id} not found`);
            }

            formulaViewModels.push({
                formula: indicator.symbol.concat('()'),
                imports: [
                    {
                        ...indicator,
                        alias: indicator.symbol,
                    },
                ],
            });
        } else if (item.type === 'formula') {
            formulaViewModels.push({
                formula: item.formula,
                imports: item.imports
                    .map((indicatorImport) => {
                        const indicator = indicators.find(
                            (x) => x.id === indicatorImport.id && x.version === indicatorImport.version,
                        );
                        if (!indicator) {
                            log.error(`Indicator with id ${indicatorImport.id} not found`);
                            return undefined;
                        }

                        return {
                            ...indicator,
                            alias: indicatorImport.alias,
                        };
                    })
                    .filter(notUndefined),
            });
        }
    }

    return formulaViewModels;
}
