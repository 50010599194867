import { getWholeSlugMap } from './http/dictionary';
import type { QueryObserverOptions } from '@tanstack/query-core';
import type { SlugMap } from 'src/lib/dictionary/dictionary';

// Constants
export const SLUG_MAP = 'slug-map';
export const WHOLE = 'whole';

export const getWholeSlugMapQuery = (): QueryObserverOptions<SlugMap> => ({
    queryKey: [SLUG_MAP, WHOLE],
    queryFn: () => getWholeSlugMap(),
});
