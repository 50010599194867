import type {
    SimpleOrderEntryWidgetModel as SimpleOrderEntryWidgetModelContract,
    OrderEntryWidgetModel as OrderEntryWidgetModelContract,
} from 'src/contracts/workspace';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { SimpleOrderEntryWidgetLegacyModel } from 'src/models/SimpleOrderEntryWidgetLegacyModel/index';
import {
    Order,
    type SimpleOrderSchemaType,
    defaultSimpleOrderEntryValues,
    mapOrderEntryFormDataToSimpleOrder,
} from 'src/routes/widgets/SimpleOrderEntryWidget/schemaLegacy';
import { initialInputState, mapOrderEntryInputsToOrderBase } from 'src/routes/widgets/SimpleOrderEntryWidget/state';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { userSelectedNewOrderEntrySymbol } from 'src/store/actions/widgets/orderEntry';
import type { ReactBindings } from 'src/types/bindings';

// TODO make this generic between all order entry widgets
// symbol and accountId are something we should make easily shared between all order entry widgets
// formSate may be unique to each order entry widget
@injectable()
export class SimpleOrderEntryWidgetLegacyModelImpl
    extends ReactiveInjectable
    implements SimpleOrderEntryWidgetLegacyModel
{
    #skipTradeConfirmation = false;
    #defaultFormValues = defaultSimpleOrderEntryValues;

    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init(tabId: string) {
        this.widgetData.init(tabId);

        // form data will exist when converting from OE to SOE
        // TODO refactor OE not to store widget.formData in redux, and refactor how we pass over form state from OE to SOE
        if (this.widget.formData) {
            this.defaultFormValues = mapOrderEntryFormDataToSimpleOrder(this.widget.formData);

            // lets remove formData to avoid any confusion, its only here as a terrible data transport hack
            // when converting widgets
            this.store.dispatch(
                updateWidget({
                    tabId: this.widgetData.tabId,
                    widgetUpdates: { formData: undefined },
                }),
            );
        }
    }

    private get widget(): SimpleOrderEntryWidgetModelContract {
        return this.widgetData.widget as SimpleOrderEntryWidgetModelContract;
    }

    set accountId(accountId: string | null) {
        this.update({ accountId }); // sync up the account id to the channel data in redux
    }

    get accountId() {
        return this.widget.accountId;
    }

    set symbol(symbol: string) {
        this.store.dispatch(userSelectedNewOrderEntrySymbol(this.widgetData.tabId, symbol));
    }

    get symbol() {
        return this.widget.symbol ?? '';
    }

    @reacts set skipTradeConfirmation(skipTradeConfirmation: boolean) {
        this.#skipTradeConfirmation = skipTradeConfirmation;
    }

    get skipTradeConfirmation() {
        return this.#skipTradeConfirmation;
    }

    @reacts set defaultFormValues(defaultFormValues: SimpleOrderSchemaType) {
        this.#defaultFormValues = defaultFormValues;
    }

    get defaultFormValues() {
        return this.#defaultFormValues;
    }

    convertToFullVariant(formState: SimpleOrderSchemaType) {
        const updates: Partial<OrderEntryWidgetModelContract> = {
            accountId: this.accountId,
            formData: mapOrderEntryInputsToOrderBase(
                {
                    ...initialInputState,
                    duration: formState.duration,
                    route: formState.route,
                    quantity: formState.quantity,
                    orderType: formState.order === Order.enum.LIMIT ? 'limit' : 'market',
                    limitPrice: formState.limitPrice ? formState.limitPrice.toString() : '',
                },
                this.symbol,
            ),
            type: 'order-entry',
            symbol: this.symbol,
            skipTradeConfirmation: true,
        };

        this.store.dispatch(
            updateWidget({
                tabId: this.widgetData.tabId,
                widgetUpdates: updates,
                tabUpdates: {
                    name: 'Order Entry',
                },
            }),
        );
    }

    /**
     * support syncing accountId and symbol to the channel data in redux
     *
     * @param updates
     * @param skipDirtyCheck
     */
    private update(
        updates: Partial<{
            symbol: string;
            accountId: string | null;
        }>,
    ) {
        this.store.dispatch(
            updateWidget({
                tabId: this.widgetData.tabId,
                widgetUpdates: {
                    ...updates,
                },
            }),
        );
    }
}
