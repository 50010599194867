import type { Bounds, FieldDescriptor, Key } from '@thinkalpha/table-client';
import { createInstance } from 'src/api';
import { appConfig } from 'src/lib/config';

const axios = createInstance({ baseURL: appConfig.tableProxy });

export type Snapshot = {
    key: Key;
    bounds: Bounds;
    fieldsDescriptors: FieldDescriptor[];
    data: Record<string, unknown>[];
    rowsCount: number;
};

export const getSnapshot = async (tableKey: Key, cookie?: string, firstRow?: number, windowSize?: number) => {
    const { data } = await axios.get<Snapshot>(`/tables/${encodeURIComponent(tableKey.sym)}/${tableKey.ex}`, {
        params: {
            firstRow: firstRow ?? 0,
            windowSize: windowSize === Infinity ? Math.pow(2, 15) : (windowSize ?? 50),
        },
        headers: {
            ...(cookie ? { Authorization: 'Bearer ' + cookie } : {}),
        },
    });

    return data;
};
