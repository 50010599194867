import { Instant } from '@js-joda/core';
import { doesAccessGrantAllowRequest } from '@thinkalpha/common/util/permissions.js';
import { ConcreteIdea, Idea, NewIdea, ScreenerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
// eslint-disable-next-line no-restricted-imports
import debounce from 'lodash/debounce';
import { distinctUntilChanged, from, map } from 'rxjs';
import { IdeaDTO } from 'src/dtos/Idea';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { SCRANNER_CHANGE_EVENT } from 'src/features/scranner/dtos/ScrannerPlanDTO';
import { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { ScreenerWidgetModel } from 'src/models/ScreenerWidgetModel';
// TODO make a mutation in src/queries/ideas to avoid this
// eslint-disable-next-line no-restricted-imports
import { createIdea, updateIdea } from 'src/queries/http/ideas';
import { getFilteredRowsCountQuery, getTotalRowsCountQuery } from 'src/queries/table';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import {
    setScreenerDisplayingBuilder,
    setScreenerDisplayingResults,
    setScreenerIdea,
    storeScreenerSplitPercentage,
} from 'src/store/actions/widgets/screener';
import type { ScreenerWidgetViewModel } from 'src/store/types';
import { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

@injectable()
export class ScreenerWidgetModelImpl extends ReactiveInjectable implements ScreenerWidgetModel {
    #name: string;
    #description: string;

    get canSaveIdea() {
        if (!this.#plan.isDirty) {
            return false;
        }

        if ('id' in this.idea && 'permissions' in this.idea) {
            return doesAccessGrantAllowRequest(
                (this.idea as ConcreteIdea).permissions.effectiveDirectAccess || undefined,
                'writer',
            );
        }

        return false;
    }

    get canSubmit() {
        return this.#hasChangesSinceLastSubmit;
    }

    get columnTemplate() {
        return this.widget.columnTemplate;
    }

    createNewScreener(): void {
        this.lastSubmit = Instant.now();
        this.#plan = new ScreenerPlanDTO(undefined, this.queryClient, this.formulaService);
        this.#name = 'New Screener';
        this.#description = '';

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(
                {
                    name: this.#name,
                    description: this.#description,
                    //plan: this.#plan.toContract(),
                    defaultUniverseId: null,
                    defaultColumnTemplateId: null,
                    isTemplate: false,
                },
                this.#plan,
            ) as IdeaDTO<ScreenerPlanDTO>,
        );

        this.#initNewScreener();
    }

    #totalTableId = v4();
    #filteredTableId = v4();
    #userViewKey = v4();

    constructor(
        @inject('ImportsManagerModel') @reacts public importsManager: ReactBindings['ImportsManagerModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('ResultsAreaModel') public resultsAreaModel: ReactBindings['ResultsAreaModel'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('FormulaService') private formulaService: ReactBindings['FormulaService'],
        @inject('TableModel') private totalTableModel: ReactBindings['TableModel'],
        @inject('TableModel') private filteredTableModel: ReactBindings['TableModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
        this.#plan = new ScreenerPlanDTO(undefined, queryClient, formulaService);
        this.#name = 'New Screener';
        this.#description = '';

        this.#initCounts();
    }

    #hasChangesSinceLastSubmit = false;

    async init(tabId: string) {
        this.widgetData.init(tabId);

        // if we have an idea, set up the plan from it
        if (this.idea) {
            this.#name = this.idea.name ?? '';
            this.#description = this.idea.description ?? '';

            if (this.idea.plan.type === 'screener') {
                this.#plan = await ScreenerPlanDTO.fromPlan(this.idea.plan, this.queryClient, this.formulaService);
            }
        }

        this.#initNewScreener();
    }

    #initCounts() {
        // Get count for all possible tickers
        {
            this.totalTableModel.init({
                requestId: this.#totalTableId,
                queryOptions: getTotalRowsCountQuery(),
            });

            this.disposableStack.use(
                this.totalTableModel.count$.subscribe((count) => {
                    this.rowCountTotal = count;
                }),
            );
        }

        // init the count for the filtered table
        // updates handled when #onScreenerChange is called
        {
            this.filteredTableModel.init({
                requestId: this.#filteredTableId,
                queryOptions: {
                    queryKey: ['screener-rows-count-tcr', this.#plan.toContract()],
                    enabled: false,
                },
                initBounds: { firstRow: 0, windowSize: 0 },
            });

            this.disposableStack.use(
                this.filteredTableModel.count$.subscribe((count) => {
                    this.rowCountFiltered = count;
                }),
            );

            this.disposableStack.use(
                from(this.filteredTableModel)
                    .pipe(
                        map(() => this.filteredTableModel.connectionStatus !== 'connected'),
                        distinctUntilChanged(),
                    )
                    .subscribe((isLoading) => {
                        this.rowCountFilteredLoading = isLoading;
                    }),
            );
        }
    }

    #initNewScreener() {
        // trigger a submit, so the results view loads any time a new screener is used
        this.lastSubmit = Instant.now();

        // set up event bindings
        const listener = this.#onScreenerChange.bind(this);
        this.#plan.changeTarget.addEventListener(SCRANNER_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#plan.changeTarget.removeEventListener(SCRANNER_CHANGE_EVENT, listener);
        });
        this.#plan.changeTarget.dispatchEvent(new Event(SCRANNER_CHANGE_EVENT));
    }

    get isShowingBuilder() {
        return this.widget.displayingForm;
    }

    set isShowingBuilder(value: boolean) {
        this.store.dispatch(setScreenerDisplayingBuilder(this.widgetData.tabId, value));
    }

    get isShowingResults() {
        return this.widget.displayingResults;
    }

    set isShowingResults(value: boolean) {
        this.store.dispatch(setScreenerDisplayingResults(this.widgetData.tabId, value));
    }

    #lastSubmit = Instant.now();
    get lastSubmit() {
        return this.#lastSubmit;
    }

    @reacts set lastSubmit(value: Instant) {
        this.#lastSubmit = value;
    }

    #rowCountFiltered = 0;
    get rowCountFiltered() {
        return this.#rowCountFiltered;
    }

    @reacts set rowCountFiltered(value: number) {
        this.#rowCountFiltered = value;
    }

    #rowCountFilteredLoading = true;
    get rowCountFilteredLoading() {
        return this.#rowCountFilteredLoading;
    }

    @reacts set rowCountFilteredLoading(value: boolean) {
        this.#rowCountFilteredLoading = value;
    }

    #rowCountTotal = 0;
    get rowCountTotal() {
        return this.#rowCountTotal;
    }

    @reacts set rowCountTotal(value: number) {
        this.#rowCountTotal = value;
    }

    #updateFilterCount = debounce(() => {
        this.filteredTableModel.updateQueryOptions(
            getFilteredRowsCountQuery(this.#plan, this.queryClient, this.#userViewKey),
        );
    }, 1000);

    #onScreenerChange() {
        // this will be called each time the screener changes, including when we first load it.
        // so set hasChangesSinceLastSubmit to true if the screener is dirty
        this.#hasChangesSinceLastSubmit = this.#plan.isDirty;

        this.#updateFilterCount();

        this.rerender();
    }

    async saveIdea(): Promise<ConcreteIdea> {
        const canPut = (idea: Idea | NewIdea): idea is ConcreteIdea => {
            return Boolean(this.idea.id);
        };

        const contractIdea = this.toIdea();

        const ideaToSave = canPut(contractIdea) ? contractIdea : null;

        if (!ideaToSave) {
            throw new Error('Cannot save idea without an id');
        }

        const newIdea = (await updateIdea(ideaToSave)) as ConcreteIdea & {
            plan: ScreenerPlan;
        };

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(
                newIdea,
                await ScreenerPlanDTO.fromPlan(newIdea.plan, this.queryClient, this.formulaService),
            ) as IdeaDTO<ScreenerPlanDTO>,
        );

        return newIdea;
    }

    async saveIdeaAs(name: string): Promise<ConcreteIdea> {
        const ideaToSave = this.toIdea(name);

        if (!ideaToSave.name) {
            throw new Error('Cannot save idea without a name');
        }

        const newIdea = (await createIdea(ideaToSave)) as ConcreteIdea & {
            plan: ScreenerPlan;
        };

        this.useIdea(
            IdeaDTO.fromIdeaAndPlan(
                newIdea,
                await ScreenerPlanDTO.fromPlan(newIdea.plan, this.queryClient, this.formulaService),
            ) as IdeaDTO<ScreenerPlanDTO>,
        );

        return newIdea;
    }

    toIdea(name?: string): NewIdea {
        return {
            ...this.idea,
            name: name ?? this.#name,
            description: this.#description,
            plan: this.#plan.toContract(),
        };
    }

    #plan: ScreenerPlanDTO;

    get screener() {
        return this.#plan;
    }

    get splitPercentage() {
        return this.widget.splitPercentage;
    }

    set splitPercentage(value: number) {
        this.store.dispatch(storeScreenerSplitPercentage(this.widgetData.tabId, value));
    }

    submit() {
        this.lastSubmit = Instant.now();
        this.#hasChangesSinceLastSubmit = false;
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    get idea() {
        return this.widget.idea;
    }

    async useIdea(idea: IdeaDTO<ScreenerPlanDTO>) {
        this.store.dispatch(
            setScreenerIdea(this.widgetData.tabId, {
                ...idea.idea,
                plan: idea.plan.toContract(),
            }),
        );

        this.#name = idea.name ?? '';
        this.#description = idea.idea.description ?? '';

        this.#plan = idea.plan;
        this.#initNewScreener();
    }

    private get widget() {
        return this.widgetData.widget as ScreenerWidgetViewModel;
    }
}
