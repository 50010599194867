import type { QueryObserverOptions } from '@tanstack/react-query';
import type { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { getUniverses, getDefaultUniverse, getUniverseById } from 'src/lib/universes';

// Constants
export const UNIVERSES = 'universes';
export const ALL = 'all';
export const DEFAULT = 'default';

/**
 * Get a universe by ID.
 */
export function getUniverseByIdQuery(id: string): QueryObserverOptions<Universe | undefined> {
    return {
        queryKey: [UNIVERSES, id],
        queryFn: async () => await getUniverseById(id),
    };
}

/**
 * Get universes for a user.
 */
export function getUniversesQuery(): QueryObserverOptions<Universe[]> {
    return {
        queryKey: [UNIVERSES, ALL],
        queryFn: () => getUniverses(),
    };
}

/**
 * Generates a query to get the default universe
 *
 * @deprecated use getDefaultUniverseRecordQuery instead, as it has much better caching
 */
export function getDefaultUniverseQuery(): QueryObserverOptions<Universe | undefined> {
    return {
        queryKey: [UNIVERSES, DEFAULT],
        queryFn: () => getDefaultUniverse(),
    };
}

/**
 * Gets the default universe from the list of universes
 */
export function getDefaultUniverseRecordQuery(): QueryObserverOptions<Universe[], unknown, Universe> {
    return {
        ...getUniversesQuery(),
        select: (universes) => universes.find((universe) => universe.default)!,
    };
}
