import { type BespokeIndicatorImport } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import type {
    Stage as StageContract,
    UnionStage as UnionStageContract,
    OutputStage as OutputStageContract,
    FilterStage as FilterStageContract,
    RiskSourceStage as RiskSourceStageContract,
    TickerSourceStage as TickerSourceStageContract,
    LegacyTableStage as LegacyTableStageContract,
    OutputColumn,
    CreateTablePayload,
} from '@thinkalpha/platform-ws-client/contracts/table.js';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';

export abstract class Stage {
    protected constructor(readonly bespokeIndicatorImports: BespokeIndicatorImport[] = []) {}
    abstract build(): StageContract;
}

export class SourceStage extends Stage {
    readonly stage: RiskSourceStageContract | TickerSourceStageContract | LegacyTableStageContract;

    private constructor(stage: SourceStage['stage']) {
        super();
        this.stage = stage;
    }

    static fromTickerUniverseId(universeId: string | undefined): SourceStage {
        return new SourceStage({ type: 'row-source', rowKey: 'ticker', universe: universeId });
    }

    static allForKey(rowKey: RiskSourceStageContract['rowKey'] | TickerSourceStageContract['rowKey']): SourceStage {
        return new SourceStage({ type: 'row-source', rowKey });
    }

    override build(): RiskSourceStageContract | TickerSourceStageContract | LegacyTableStageContract {
        return this.stage;
    }

    intoFilter(
        filterStage: Omit<FilterStageContract, 'inputStage'>,
        bespokeIndicatorImports: BespokeIndicatorImport[] = [],
    ): FilterStage {
        return FilterStage.fromInputStage(this, filterStage, bespokeIndicatorImports);
    }

    intoOutput(stage: Omit<OutputStage['stage'], 'inputStage'>, templateColumns: OutputStage['templateColumns']) {
        return OutputStage.fromInputStage(this, stage, templateColumns);
    }
}

export class FilterStage extends Stage {
    readonly stage: FilterStageContract;

    private constructor(stage: FilterStage['stage'], bespokeIndicatorImports: FilterStage['bespokeIndicatorImports']) {
        super(bespokeIndicatorImports);
        this.stage = stage;
    }

    override build(): FilterStageContract {
        return this.stage;
    }

    static fromInputStage(
        source: Stage,
        stage: Omit<FilterStageContract, 'inputStage'>,
        bespokeIndicatorImports: BespokeIndicatorImport[] = [],
    ): FilterStage {
        return new FilterStage(
            {
                ...stage,
                imports: stage.imports.map((importRef) =>
                    importRef instanceof IndicatorImportModel
                        ? importRef.toRef().toContractType()
                        : importRef instanceof IndicatorImportRefModel
                          ? importRef.toContractType()
                          : importRef,
                ),
                inputStage: source.build(),
            },
            bespokeIndicatorImports,
        );
    }

    intoOutput(stage: Omit<OutputStage['stage'], 'inputStage'>, templateColumns: OutputStage['templateColumns']) {
        return OutputStage.fromInputStage(this, stage, templateColumns);
    }
}

export class OutputStage extends Stage {
    build(): OutputStageContract {
        const stage: OutputStageContract = {
            ...this.stage,
            type: 'output',
            columns: (this.templateColumns as OutputColumn[]).concat(
                this.bespokeIndicatorImports.map(
                    (ind): OutputColumn => ({
                        id: ind.id,
                        indicator: {
                            ...ind,
                            formula: {
                                ...ind.formula,
                                imports: ind.formula.imports.map((importRef) =>
                                    importRef instanceof IndicatorImportModel
                                        ? importRef.toRef().toContractType()
                                        : importRef instanceof IndicatorImportRefModel
                                          ? importRef.toContractType()
                                          : importRef,
                                ),
                            },
                        },
                        type: 'indicator',
                    }),
                ),
            ),
        };

        return stage;
    }

    private constructor(
        stage: OutputStage['stage'],
        bespokeIndicatorImports: OutputStage['bespokeIndicatorImports'],
        templateColumns: OutputStage['templateColumns'],
    ) {
        super(bespokeIndicatorImports);
        this.stage = stage;
        this.templateColumns = templateColumns;
    }

    static fromInputStage(
        inputStage: Stage,
        output: Omit<OutputStage['stage'], 'inputStage'>,
        templateColumns: OutputStage['templateColumns'],
    ): OutputStage {
        return new OutputStage(
            {
                inputStage: inputStage.build(),
                ...output,
            },
            inputStage.bespokeIndicatorImports,
            templateColumns,
        );
    }

    intoTable(userViewKey?: CreateTablePayload['userViewKey']): CreateTablePayload {
        return {
            leafStage: this.build(),
            userViewKey,
            bespokeIndicators: this.bespokeIndicatorImports.length
                ? this.bespokeIndicatorImports.map((i) => ({
                      ...i,
                      formula: {
                          ...i.formula,
                          imports: i.formula.imports.map((ref) => {
                              return ref instanceof IndicatorImportModel
                                  ? ref.toRef().toContractType()
                                  : ref instanceof IndicatorImportRefModel
                                    ? ref.toContractType()
                                    : ref;
                          }),
                      },
                  }))
                : undefined,
        };
    }

    readonly templateColumns: OutputColumn[];

    readonly stage: Omit<OutputStageContract, 'type' | 'columns'>;
}

export class UnionStage extends Stage {
    readonly stage: UnionStageContract;

    private constructor(stage: UnionStageContract, bespokeIndicatorImports: BespokeIndicatorImport[]) {
        super(bespokeIndicatorImports);
        this.stage = stage;
    }

    static from(stages: Stage[]) {
        const allBespokeIndicatorImports = stages.flatMap((stage) => stage.bespokeIndicatorImports);
        return new UnionStage(
            {
                type: 'union',
                inputStages: stages.map((stage) => stage.build()),
            },
            allBespokeIndicatorImports,
        );
    }

    override build(): UnionStageContract {
        return this.stage;
    }

    intoOutput(stage: Omit<OutputStage['stage'], 'inputStage'>, templateColumns: OutputStage['templateColumns']) {
        return OutputStage.fromInputStage(this, stage, templateColumns);
    }
}
