import { YearMonth, LocalDate } from '@js-joda/core';
import { type Sample } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { isEqual } from 'es-toolkit';
import { map, shareReplay, from, filter, distinctUntilChanged, switchMap, of, EMPTY } from 'rxjs';
import { container } from 'src/StaticContainer';
import type {
    AlphaLensPage,
    AlphaLensSection,
    AlphaLensTab,
    AlphaLensWidgetModel as LensWidgetModel,
} from 'src/contracts/workspace';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { getCompanyLogoFromSymbol } from 'src/lib/newQuant';
import { getSearchAlphaIdeaFromFormula } from 'src/lib/runInfo';
import type { AlphaLensWidgetModel } from 'src/models/AlphaLensWidgetModel';
import { getNewsQuery } from 'src/queries/news';
import { getAlphaLensDefaultWidgetQuery } from 'src/queries/userPreferences';
import { createChartFromSymbol, createSearchAlphaWithIdea } from 'src/store/actions/container';
import { userSelectedNewAlphaLensSymbol } from 'src/store/actions/widgets/lens';
import type { ReactBindings } from 'src/types/bindings';
import { v4 } from 'uuid';

const log = container.get('Logger').getSubLogger({ name: 'AlphaLensWidgetModel' });

@injectable()
export class AlphaLensWidgetModelImpl extends ReactiveInjectable implements AlphaLensWidgetModel {
    #symbol: string = '';

    #header: AlphaLensTab | null = null;
    #pages: AlphaLensPage[] = [];
    #currentPageId: string = '';
    #newsFilter: string = 'all';
    #dataListFontSize: 'small' | 'medium' | 'large' = 'small';
    #goToDate: LocalDate | null = null;
    #displayMonth: YearMonth = YearMonth.now();

    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init(tabId: string) {
        this.widgetData.init(tabId);

        const storeSubscription = from(this.store);
        const symbol$ = storeSubscription.pipe(
            map((state) => {
                const tab = state.tab.byId[tabId];
                if (!tab) return null;

                const widget = tab.widget;
                if (widget.type !== 'alpha-lens') {
                    throw new Error('Widget type is not alpha-lens');
                }

                return widget.symbol;
            }),
            filter((symbol): symbol is string => (symbol ? symbol.length > 0 : false)),
            distinctUntilChanged(),
            shareReplay(1),
        );

        // There must be a way to do pages$ and header$ in one go
        // but TS and I are not getting along, so this is a path to done
        // TODO revisit
        const header$ = storeSubscription.pipe(
            switchMap((state) => {
                const tab = state.tab.byId[tabId];
                if (!tab) return EMPTY;

                const widget = tab.widget;
                if (widget.type !== 'alpha-lens') {
                    throw new Error('Widget type is not alpha-lens');
                }

                if (widget.pages) return of(widget.pages);

                return from(
                    this.queryClient
                        .fetchUserQuery(getAlphaLensDefaultWidgetQuery())
                        .then((data) => data.header ?? undefined),
                );
            }),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            map((header: AlphaLensTab) => {
                if (!header) return undefined;

                return this.tabMapper(header);
            }),
        );

        const pages$ = storeSubscription.pipe(
            switchMap((state) => {
                const tab = state.tab.byId[tabId];
                if (!tab) return EMPTY;

                const widget = tab.widget;
                if (widget.type !== 'alpha-lens') {
                    throw new Error('Widget type is not alpha-lens');
                }

                if (widget.pages) return of(widget.pages);

                return from(
                    this.queryClient.fetchUserQuery(getAlphaLensDefaultWidgetQuery()).then((data) => data.pages ?? []),
                );
            }),
            filter((pages) => pages.length > 0),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            map((pages) => {
                return pages.map((page) => {
                    return {
                        id: this.generateId(),
                        name: page.name,
                        sections: page.sections.map((section): AlphaLensSection => {
                            return {
                                ...section,
                                id: this.generateId(),
                                tabs: section.tabs.map(this.tabMapper),
                            };
                        }),
                    };
                });
            }),
            shareReplay(1),
        );

        const syncSymbol = symbol$.subscribe((symbol) => {
            this.symbol = symbol;

            // Fetch to warm the news into the cache
            this.queryClient.fetchQuery(getNewsQuery(symbol, false));
        });

        const syncHeader = header$.subscribe((header) => {
            if (header) {
                this.header = header;
            }
        });

        const syncPages = pages$.subscribe((pages) => {
            this.pages = pages;

            // TODO remove this once new data model is in place
            if (!this.header && pages.length > 0) {
                this.header = pages[0]?.sections[0].tabs[0];
            }
        });

        this.disposableStack.use({
            [Symbol.dispose]: () => {
                log.debug({ message: 'Disposing alpha lens observables' });

                syncPages.unsubscribe();
                syncSymbol.unsubscribe();
                syncHeader.unsubscribe();
            },
        });
    }

    get channelId(): string | null {
        return this.widgetData.channelId;
    }

    private get widget() {
        return this.widgetData.widget as LensWidgetModel;
    }

    get symbol() {
        return this.#symbol;
    }

    @reacts set symbol(symbol: string) {
        this.#symbol = symbol;
        this.#goToDate = null;
        this.#displayMonth = YearMonth.now();
    }

    get currentPageId() {
        if (!this.#currentPageId) {
            return this.#pages[0]?.id || '';
        }
        return this.#currentPageId;
    }

    @reacts set currentPageId(currentPageId) {
        this.#currentPageId = currentPageId;
    }

    get newsFilter() {
        return this.#newsFilter;
    }

    @reacts set newsFilter(newsFilter) {
        this.#newsFilter = newsFilter;
    }

    get pages() {
        return this.#pages;
    }

    @reacts set pages(pages: AlphaLensPage[]) {
        this.#pages = pages;
    }

    get header() {
        return this.#header;
    }

    @reacts set header(header: AlphaLensTab | null) {
        this.#header = header;
    }

    onChartOpen = () => {
        this.store.dispatch(
            createChartFromSymbol({
                defaultSymbol: this.symbol,
                channelId: this.channelId,
            }),
        );
    };

    addStrategyWidgetWithSuggestionToDashboard = async (sample: Sample) => {
        const idea = await getSearchAlphaIdeaFromFormula(sample.formula, sample.nlpExpression || '', sample.imports);

        if (idea) {
            this.store.dispatch(
                createSearchAlphaWithIdea({
                    channelId: this.channelId,
                    idea,
                }),
            );
        }
    };

    getLogoUrl = (symbol: string): Promise<string> => {
        return getCompanyLogoFromSymbol(symbol)
            .then((logoData) => {
                const logos = logoData?.logos;

                if (!logos) return null;

                return (
                    logos.markVectorDark ??
                    logos.markDark ??
                    logos.markCompositeDark ??
                    logos.logoVectorDark ??
                    logos.logoDark ??
                    null
                );
            })
            .catch(() => null)
            .then((logo) => logo || '');
    };

    // Component will call in a `useEvent`
    changeSymbol = (symbol: string) => {
        this.store.dispatch(userSelectedNewAlphaLensSymbol(this.widgetData.tabId, symbol));
    };

    @reacts set dataListFontSize(size) {
        this.#dataListFontSize = size;
    }

    get dataListFontSize() {
        return this.#dataListFontSize;
    }

    @reacts set goToDate(date: LocalDate | null) {
        this.#goToDate = date;

        this.#displayMonth = date ? YearMonth.from(date) : YearMonth.now();
    }

    get goToDate() {
        return this.#goToDate;
    }

    @reacts set displayMonth(date: YearMonth) {
        this.#displayMonth = date;
    }

    get displayMonth() {
        return this.#displayMonth;
    }

    private generateId() {
        return v4().replace(/-/g, '');
    }

    private tabMapper = (tab: AlphaLensTab): AlphaLensTab => {
        switch (tab.type) {
            case 'dictionary-list':
                return {
                    id: tab.id ? tab.id : this.generateId(),
                    type: 'dictionary-list',
                    name: tab.name,
                    segments: tab.segments.map((segment) => {
                        return {
                            ...segment,
                            id: this.generateId(),
                            items: segment.items.map((item) => {
                                return {
                                    ...item,
                                    id: this.generateId(),
                                };
                            }),
                        };
                    }),
                };

            case 'symbol-info':
                return {
                    id: this.generateId(),
                    type: 'symbol-info',
                    name: tab.name,
                    sourceData: {
                        price: tab.sourceData.price
                            ? {
                                  ...tab.sourceData.price,
                                  id: this.generateId(),
                              }
                            : undefined,
                        volume: tab.sourceData.volume
                            ? {
                                  ...tab.sourceData.volume,
                                  id: this.generateId(),
                              }
                            : undefined,
                        change: tab.sourceData.change
                            ? {
                                  ...tab.sourceData.change,
                                  id: this.generateId(),
                              }
                            : undefined,
                        percentChange: tab.sourceData.percentChange
                            ? {
                                  ...tab.sourceData.percentChange,
                                  id: this.generateId(),
                              }
                            : undefined,
                    },
                };

            default:
                return tab;
        }
    };
}
