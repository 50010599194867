import { QueryClient } from '@tanstack/query-core';
import { fromQuery, handleQueryErrors } from '@thinkalpha/common/util/fromQuery.js';
import { IfThenFieldInView, type WatchlistPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { map, shareReplay } from 'rxjs';
import { container } from 'src/StaticContainer';
import { IdeaPlanDTO } from 'src/dtos/Idea';
import { SearchPlanDTO } from 'src/dtos/Idea/SearchPlanDTO';
import { IndicatorImportModel } from 'src/dtos/IndicatorImport';
import { createGuidedConditionModel } from 'src/features/scranner/dtos/ScrannerPlanDTO';
import { ConditionGroupModel } from 'src/features/searchAlpha/dtos/ConditionGroupModel';
import { conditionGroupModelToIfThenGroup } from 'src/features/searchAlpha/dtos/ConditionGroupModel/conditionGroupModelToIfThenGroup';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { type SlugMap } from 'src/lib/dictionary/dictionary';
import { FilterStage, SourceStage, type Stage } from 'src/lib/tableStages';
import { getWholeSlugMapQuery } from 'src/queries/slugMap';
import { v4 } from 'uuid';

const _log = container.get('Logger').getSubLogger({ name: 'feat:watchlist:WatchlistPlanDTO' });

export const WATCHLIST_CHANGE_EVENT = 'watchlist-change';
export type WatchlistChangeEvent = CustomEvent<void>;

const slugMap$ = fromQuery(new QueryClient({}), getWholeSlugMapQuery()).pipe(
    handleQueryErrors(),
    map((x) => x.data),
    shareReplay(1),
);

let slugMap: SlugMap | undefined;
slugMap$.subscribe((x) => {
    slugMap = x;
});

interface WatchlistPlanDTOInitializer {
    isDirty: boolean;
    includeList: string[];
}

interface WatchlistPlanDTOState {
    isDirty: boolean;
    includeList: string[];
}

export class WatchlistPlanDTO extends IdeaPlanDTO {
    constructor(state: WatchlistPlanDTOInitializer | undefined) {
        super();

        this.#state = state ?? {
            isDirty: false,
            includeList: [],
        };
    }

    toContract(): WatchlistPlan {
        return {
            type: 'watchlist',
            includeList: this.#state.includeList,
        };
    }

    static fromPlan(plan: WatchlistPlan): WatchlistPlanDTO {
        return new WatchlistPlanDTO({
            isDirty: false,
            includeList: plan.includeList,
        });
    }

    #state: WatchlistPlanDTOState;

    readonly changeTarget = new EventTarget();

    private set state(newState: WatchlistPlanDTOState) {
        this.#state = newState;
        this.changeTarget.dispatchEvent(new CustomEvent(WATCHLIST_CHANGE_EVENT) satisfies WatchlistChangeEvent);
    }

    setIncludeList(listOrFn: string[] | ((currentList: string[]) => string[])) {
        const includeList = typeof listOrFn === 'function' ? listOrFn(this.#state.includeList) : listOrFn;

        this.state = {
            ...this.#state,
            isDirty: true,
            includeList,
        };
    }

    get includeList(): readonly string[] {
        return this.#state.includeList;
    }

    private static makeEmptySearchPlan() {
        return SearchPlanDTO.fromPlanAndImports(
            {
                type: 'if-then',
                root: {
                    type: 'group',
                    name: 'TODO',
                    collapsed: false,
                    enabled: true,
                    lines: [
                        {
                            type: 'line',
                            enabled: true,
                            id: v4(),
                            mode: 'formula',
                            formula: 'true == false',
                            description: '',
                            fieldInView: IfThenFieldInView.formula,
                        },
                    ],
                    id: v4(),
                    description: '',
                    operator: 'and',
                },
                imports: [],
            },
            [],
        );
    }

    get isDirty() {
        return this.#state.isDirty;
    }

    override async toStage(
        inputStage: Stage | null,
        _queryClient: ThinkAlphaQueryClient,
        validationMode: boolean,
    ): Promise<FilterStage | null> {
        if (!validationMode) {
            const result = await this.renderToFormula();
            if (!result) return null;

            const { formula, imports } = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula,
                    imports,
                },
                [],
            );
        } else {
            const result = await this.getValidationModeFormulaAndIndicators();
            if (!result) return null;

            const [bespokeIndicators, formula] = result;

            return FilterStage.fromInputStage(
                inputStage ?? SourceStage.allForKey('ticker'),
                {
                    type: 'filter',
                    formula: formula.formula,
                    imports: formula.imports,
                },
                bespokeIndicators,
            );
        }
    }

    async toSearchPlan(): Promise<SearchPlanDTO> {
        if (!this.#state.includeList.length) {
            return WatchlistPlanDTO.makeEmptySearchPlan();
        }

        if (!slugMap) {
            slugMap = await new Promise<SlugMap>((resolve) => {
                using _sub = slugMap$.subscribe((x) => {
                    if (x) resolve(x);
                });
            });
        }

        const imports: IndicatorImportModel[] = [];

        const symbolIndicator = slugMap['symbol'];
        const symbolIndicatorImportModel = IndicatorImportModel.fromIndicatorAndImports(symbolIndicator, imports);

        let inclusions: ConditionGroupModel | undefined;
        if (this.#state.includeList.length > 0) {
            if (!imports.includes(symbolIndicatorImportModel)) {
                imports.push(symbolIndicatorImportModel);
            }

            inclusions = new ConditionGroupModel({
                id: v4(),
                label: 'Symbol Inclusions',
                children: [],
                booleanOperator: 'or',
                isExpanded: true,
                isSelfEnabled: true,
                parent: false,
            });

            for (const symbol of this.#state.includeList) {
                createGuidedConditionModel(inclusions, symbolIndicatorImportModel.alias, '==', `"${symbol}"`);
            }
        }

        if (!inclusions) {
            return WatchlistPlanDTO.makeEmptySearchPlan();
        }

        return SearchPlanDTO.fromPlanAndImports(
            {
                type: 'if-then',
                root: conditionGroupModelToIfThenGroup(inclusions),
                imports: imports.values().toArray(),
            },
            imports,
        );
    }

    async renderToFormula() {
        const searchPlanDTO = await this.toSearchPlan();
        return searchPlanDTO.renderToFormula();
    }

    async getValidationModeFormulaAndIndicators() {
        const searchPlanDTO = await this.toSearchPlan();
        return searchPlanDTO.getValidationModeFormulaAndIndicators();
    }
}
