import type { LocalDate } from '@js-joda/core';
import type {
    Idea,
    IfThenResearchPlan,
    ScannerPlan,
    ScreenerPlan,
    WatchlistPlan,
} from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    AlphaLensWidgetModel,
    ExecutionsWidgetModel,
    Layout,
    LocatesWidgetModel,
    MessagesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    OrdersWidgetModel,
    PositionsWidgetModel,
    StrategyWidgetModel,
    TableWidgetModel,
    TimeSeriesWidgetModel,
    WidgetContainer,
    WidgetTab,
    Workspace,
    SimpleOrderEntryWidgetModel,
    SearchAlphaWidgetModel,
    ScreenerWidgetModel,
    ScannerWidgetModel,
    NewsWidgetModel,
    EventsWidgetModel,
    FilingsWidgetModel,
    WatchlistWidgetModel,
    AlphabotWidgetModel,
} from 'src/contracts/workspace';

// eslint-disable-next-line @typescript-eslint/consistent-type-exports
export * from './sagaContext';

export interface UserDetails {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    /**
     * isSuperuser is a flag for global, unrestricted superuser permissions.
     */
    isSuperuser: boolean;
    isDeveloper: boolean;
}

export interface WorkspaceViewModel extends Omit<Workspace, 'containers' | 'channels'> {
    containerIds: string[];
    activeContainerId?: string;
    isDirty: boolean;
    isDefaultWorkspace: boolean;
    activeBorderColor: string;
}

export interface ContainerViewModel extends Omit<WidgetContainer, 'tabs' | 'layout'> {
    tabIds: string[];
}

export interface ContainerLayoutViewModel extends Layout {
    containerId: string;
}

export interface StrategyWidgetViewModel extends StrategyWidgetModel {}

export interface WatchlistWidgetViewModel extends Omit<WatchlistWidgetModel, 'idea'> {
    // UI always materializes the idea
    idea: Idea & { plan: WatchlistPlan };
}

export interface MessagesWidgetViewModel extends MessagesWidgetModel {
    symbolFilter: string;
}

export interface PositionsWidgetViewModel extends PositionsWidgetModel {
    symbolFilter: string;
}
export interface OrdersWidgetViewModel extends OrdersWidgetModel {
    symbolFilter: string;
}

export interface ExecutionsWidgetViewModel extends ExecutionsWidgetModel {
    symbolFilter: string;
}

export interface OrdersWidgetViewModel extends OrdersWidgetModel {
    symbolFilter: string;
}

export interface EventsWidgetViewModel extends EventsWidgetModel {
    gotoDate: LocalDate | null;
}
export interface NewsWidgetViewModel extends NewsWidgetModel {}
export interface ScreenerWidgetViewModel extends Omit<ScreenerWidgetModel, 'idea'> {
    // UI always materializes the idea
    idea: Idea & { plan: ScreenerPlan };
}

export interface ScannerWidgetViewModel extends Omit<ScannerWidgetModel, 'idea'> {
    // UI always materializes the idea
    idea: Idea & { plan: ScannerPlan };
}

export interface SearchAlphaWidgetViewModel extends Omit<SearchAlphaWidgetModel, 'idea'> {
    // UI always materializes the idea
    idea: Idea & { plan: IfThenResearchPlan };
}

export interface FilingsWidgetViewModel extends FilingsWidgetModel {
    gotoDate: LocalDate | null;
}

export type WidgetViewModel =
    | AccountsWidgetModel
    | AggregatedPositionsWidgetModel
    | AlphaLensWidgetModel
    | AlphabotWidgetModel
    | EventsWidgetModel
    | EventsWidgetViewModel
    | ExecutionsWidgetViewModel
    | FilingsWidgetViewModel
    | LocatesWidgetModel
    | MessagesWidgetViewModel
    | NewsWidgetViewModel
    | OrderBlotterWidgetModel
    | OrderEntryWidgetModel
    | OrdersWidgetViewModel
    | PositionsWidgetViewModel
    | ScannerWidgetViewModel
    | ScreenerWidgetViewModel
    | SearchAlphaWidgetViewModel
    | SimpleOrderEntryWidgetModel
    | StrategyWidgetViewModel
    | TableWidgetModel
    | TimeSeriesWidgetModel
    | WatchlistWidgetViewModel;

export type WidgetViewModelKinds = Array<WidgetViewModel['type']>[number];

export type InferWidgetViewModel<T extends WidgetViewModelKinds> = Extract<WidgetViewModel, { type: T }>;

export interface WidgetTabViewModel<T extends WidgetViewModel = WidgetViewModel> extends Omit<WidgetTab, 'widget'> {
    containerId: string;
    channelId: string | null;
    isActive: boolean;
    widget: T;
    // Poorly named dummy properties to not forget future plans
    // channelSubscriptionSettings?: {
    //     dontPublishTo?: WidgetType[];
    //     dontListenTo?: WidgetType[];
    //     publicationPriorities: [];
    // };
}

export const ApplicationMenu = {
    loggedOutOpen: 'logged-out::open',
    loggedOutClosed: 'logged-out::closed',
    dashboard: 'dashboard',
};

export type ApplicationMenuType = (typeof ApplicationMenu)[keyof typeof ApplicationMenu];

export type VerifyLeavingWorkspaceModal = {
    type: 'verify-leaving-workspace';
};

export type VerifySavingWorkspaceModal = {
    type: 'verify-saving-workspace';
};

export type GetNewWorkspaceNameModal = { type: 'get-new-workspace-name'; title: string };
export type WorkspaceLibraryModal = { type: 'workspace-library' };

export type ModalInstance =
    | VerifyLeavingWorkspaceModal
    | VerifySavingWorkspaceModal
    | GetNewWorkspaceNameModal
    | WorkspaceLibraryModal;

export interface ClientCredentials {
    clientId: string;
    clientSecret: string;
}
